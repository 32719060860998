import {useAlterBox} from "../../../../Modals/AlertModal/Context";
import {useState} from "react";
import DummyData from "../../../dashboard/tables/ClientTable/varaibales/DummyData";
import PageTitle from "../../../../components/utils/PageTitle";
import {Link, useNavigate} from "react-router-dom";
import TableRow from "../../../../components/Table/TableRow";
import {AiFillEye, AiOutlineAppstoreAdd} from "react-icons/ai";
import TableContainer from "../../../../components/Table/TableContainer";
import Table from "../../../../components/Table/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableCell from "../../../../components/Table/TableCell";
import TableBody from "../../../../components/Table/TableBody";
import TableLoadingRow from "../../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../../components/Table/TableEmpty";
import Avatar from "../../../../components/Image/Avatar/Avatar";
import Badge from "../../../../components/Badges/Badge";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../../../components/Table/TableFooter";
import Paginator from "../../../../components/Table/Paginator";
import dummyPatientList from "../../../../utils/dummy-data/dummy-patient-list";
import download, {getUri} from "../../../../utils/utils";
import {FaFileDownload} from "react-icons/fa";
import useListApi from "../../../../Hooks/useListApi";
import URLS from "../../../../API/Axios/URLS";
import moment from "moment";
import SearchInput from "../../../../components/fields/search-input/search-input";
import {BiCalendarCheck} from "react-icons/bi";

export default function DevicesTable({className, isModal,notAssignedOnly, onRowSelect, ...props}) {


    let [data, filter, isLoading, deviceActions] = useListApi(URLS.DeviceList, {
        search: "",
        showNotAssigned: notAssignedOnly
    });
    const navigate = useNavigate();

    function onConfirm() {

    }

    function onCancle() {

    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            {!isModal && <div className={"w-full flex mb-10 justify-end items-center"}>
                <Link title={"Add Device"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"/app/devices/add"}>
                    <AiOutlineAppstoreAdd className={"text-base"}/>
                </Link>
            </div>
            }
            {
                <div
                    className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
                >
                    <SearchInput
                        hideRightIcon={true}
                        onChange={(e) => {
                            deviceActions.updateFilter({
                                search: e.target.value
                            })
                        }}
                        outerClassName={"border-[1px]  rounded-full"}
                        placeholder={"Search Device"}
                    />


                </div>
            }

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Device ID</TableCell>
                            <TableCell>IMEI</TableCell>
                            <TableCell>Model No.</TableCell>
                            <TableCell>Device Type </TableCell>
                            <TableCell>Assignment</TableCell>
                            <TableCell>Status</TableCell>
                            {!isModal && <TableCell>Actions</TableCell>}
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((row, i) => {
                            return <TableRow
                                onClick={() => {
                                    if (onRowSelect)
                                        onRowSelect(row)
                                }}
                                className={"cursor-pointer"} key={i}>
                                <TableCell>
                                    <span className="text-sm">{

                                        row?.deviceId
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        row?.imei
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{
                                        row?.modelNo
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        row?.deviceType
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm">{

                                        row?.isAssigned ? <span className={"text-green-600"}>Assigned</span> : <span className={"text-red-600"}>Not Assigned</span>
                                    }</p>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        row?.status
                                    }</span>
                                </TableCell>
                                {
                                    !isModal && <TableCell>
                                        <div className={"flex gap-2"}>

                                            <Link to={"update/" + row.id}>
                                                <MdEditNote title={"edit"}

                                                            className={"cursor-pointer"}/>
                                            </Link>



                                        </div>
                                    </TableCell>
                                }
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>



                </TableFooter>

            </TableContainer>
        </div>

    )
}
