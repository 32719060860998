export default function Avatar({className,src, ...props}) {

    return (
        <img className={` ${className?className:""} object-cover rounded-full w-10 h-10 `}  src={
            src?src:"/icons/user-icon.svg"
        } {...props} />


    )
}
