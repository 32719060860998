import {useState} from "react";
import TabItem from "./tab-item";


export default function Tabs({className, items, value, onChange, ...props}) {
    const [selectedTab, setSelectedTab] = useState(value || items?.[0]);


    return (
        <div className={` ${className} bg-gray-200 dark:bg-opacity-5 flex flex-row gap-2 rounded-md w-full p-2`}>
            {
                items.map((item, index) => {
                    return (
                        <TabItem
                            variant={selectedTab === item ? "active" : "default"}
                            onClick={() => {
                                if (onChange) {
                                    onChange(item)
                                }
                                setSelectedTab(item)

                            }}

                        >
                            {item.label}
                        </TabItem>
                    )
                })
            }
        </div>

    )
}