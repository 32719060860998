import DpCircle from "./components/DpCircle";
import {FaMarker} from "react-icons/fa";
import {GiPositionMarker} from "react-icons/gi";
import React, {useState} from "react";
import {TfiMarkerAlt} from "react-icons/tfi";
import {getUri} from "../../utils/utils";
import {FcGoogle} from "react-icons/fc";
import {toast} from "react-toastify";
import FormItem from "../../components/Form/FormItem/FormItem";
import FormButton from "../../components/Form/Button/FormButton";
import {useSession} from "../../redux/Reducers/AuthReducer";
import axiosInstance from "../../API/Axios/AxiosConfig";
import URLS from "../../API/Axios/URLS";



function ProfileBox({className, heading, value, ...props}) {

    return (
        <div className={`${className} flex px-4 py-3 justify-center items-center  flex-row gap-2`}>
            <p className={"font-semibold flex-1 text-base"}>
                {heading}
            </p>
            <p className={"font-normal text-sm text-gray-500"}>
                {value}
            </p>
        </div>

    )
}

function GoogleBox({className, ...props}) {

    return (
        <div className={`${className} flex justify-center items-center flex-row gap-2`}>
            <FcGoogle/>

            Google
        </div>

    )
}

export default function UpdateProfile({className, ...props}) {
    const {session,authActions} = useSession();
    const [form, setForm] = useState({});//[form, setForm
    const [error, setError] = useState({});//[form, setForm
    const [loading, setLoading] = useState(false);//[form, setForm
    const profile = session?.profile;

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)
        setError({})
        let mytoast = toast.loading("Updating profile...",{
            toastId: "update-profile",
            hideProgressBar: false,
            isLoading: false,
            autoClose: 2000,
        })
        axiosInstance.post(URLS.updateProfile, form).then((res) => {

            authActions.updateSession({
                ...session,
                profile:{
                    ...session?.profile,
                    ...res?.data
                }
            })
            toast.update(mytoast, {
                render: "Profile updated successfully",
                type: "success",

            })
        }).catch((err) => {
            toast.update(mytoast, {
                render: "Error updating profile",
                type: "error",
            })

            setError(err?.response?.data)
        }).finally(()=>setLoading(false))
    }

    return (
        <div className={`flex flex-col gap-12  ${className}`}>
            <div className={"flex flex-row grid- justify-center items-center gap-3"}>
                <DpCircle src={getUri(session?.profile?.dp_uri?.file)}/>
                <div className={"flex flex-1 flex-col justify-start items-start gap-1"}>
                    <p className={'font-bold text-sm lg:text-xl'}>{profile?.full_name}</p>
                    <p className={'font-normal text-xs lg:text-sm text-gray-400 flex flex-row justify-center items-center'}>

                    </p>
                </div>


            </div>
            <form
                onSubmit={handleSubmit}
                className={"grid grid-cols-1 gap-2  lg:grid-cols-2 "}>
                <FormItem variant={"auth"} message={error?.full_name} type={"error"} className={"col-span-1"}
                          label={"Full Name"}>
                    <input type="text" className={"w-full"}
                           defaultValue={profile?.full_name}
                           value={form?.full_name}
                           typeof={"text"}

                           onChange={(e) => setForm({...form, full_name: e.target.value})}
                    />
                </FormItem>
                <FormItem variant={"auth"} message={error?.email} type={"error"} className={"col-span-1"}
                          label={"Email*"}>
                    <input type="email" className={"w-full "}
                           defaultValue={profile?.email}
                           disabled={true}

                    />
                </FormItem>

                <FormItem variant={"auth"} message={error?.old_password} type={"error"} className={"col-span-1"}
                          label={"Old Password"}>
                    <input type="password" className={"w-full"}
                           value={profile?.old_password}
                           placeholder={"**********"}
                           onChange={(e) => setForm({...form, old_password: e.target.value})}
                    />
                </FormItem>
                <FormItem variant={"auth"} message={error?.password} type={"error"} className={"col-span-1"}
                          label={"New Password"}>
                    <input type="password" className={"w-full"}
                           value={profile?.password}
                           placeholder={"**********"}
                           onChange={(e) => setForm({...form, password: e.target.value})}
                    />
                </FormItem>



                <FormButton className={"col-span-1 m-auto lg:w-1/2 lg:col-span-2"} loading={loading} >
                    Update Profile
                </FormButton>

            </form>
        </div>

    )
}