import PageTitle from "../../../components/utils/PageTitle";
import PatientDetailCard from "./component/detail-card";
import PatientFiles from "./component/patient-files";
import PatientNotes from "./component/patient-notes";
import Appointment from "./component/appointment";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";
import useListApi from "../../../Hooks/useListApi";
import RecentVitals from "../../Vitals/RecentVitals";
import AssignedDevices from "./component/AssignedDevices";

export default function PatientProfile({className, ...props}) {
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const navigation = useNavigate();
    const [FileData, setFileData] = useState({});


    function fetchUserFile(email) {
        axiosInstance.get(URLS.ReportFileList, {
            params: {
                is_hidden: false,
                search: "",
                user_email: email,
                page_size: 4,
            }
        }).then(res => {
            setFileData(res.data)
        })
    }

    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.PatientCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setData(
                {
                    ...data,
                    user: {
                        ...data.user,
                        date_of_birth: moment(data.user.date_of_birth).format("YYYY-MM-DD")
                    }
                }
            )
            setLoadingData(false);
            fetchUserFile(data.user?.email)
        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong", {
                toastId: "patient-profile-error",
            })
            navigation("/app/patient")
        })

    }

    useEffect(() => {
        getFormData();
    }, []);


    if (loadingData)
        return <PageLoading/>

    return (
        <div className={` ${className} pb-20`}>
            <PageTitle>
                {
                    data?.user?.full_name
                }
            </PageTitle>
            <div className={"grid grid-cols-1 md:grid-cols-3 gap-4"}>
                <div className={"md:col-span-2 "}>
                    <PatientDetailCard
                        data={data}
                    />
                </div>
                <div className={"w-full  flex flex-col gap-4 "}>
                    <PatientFiles
                        data={FileData}
                        email={data?.user?.email}
                    />



                </div>


                <div className={"md:col-span-2   "}>
                    <Appointment patientId={id} />

                </div>
                <div className={"flex-1 "}>
                    {/*<PatientNotes/>*/}
                    <AssignedDevices
                        id={id}
                    />

                </div>


            </div>
            <RecentVitals/>
        </div>

    )
}
