import React from "react";
import DoctorTable from "./component/doctor-table";
import PageTitle from "../../../components/utils/PageTitle";
import DoctorsModal from "../../../Modals/doctor-slection-modal/doctor-selction-modal";


export default function DoctorList({className, ...props}) {

    return (
        <div className={` ${className}`}>

            <PageTitle>
                Doctor List
            </PageTitle>
            <DoctorTable/>
        </div>

    )
}