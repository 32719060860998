import MenuDropdown from "../../dropdown/MenuDropdown/MenuDropdown";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import FormNoneFieldErrors from "../../Form/FormNoneFieldErrors";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {AiFillCloud} from "react-icons/ai";
import axiosInstance from "../../../API/Axios/AxiosConfig";

export default function CreateDirectoryDropDown({className, owner, refresh, ...props}) {
    const [form, error, loading, setFormData, actions] = useForm({
        name: "",
        is_hidden: false,
        owner: owner,

    })

    function createDirectory() {
        if (loading) return
        if (!form?.file) {
            actions.setError({non_field_errors: ["File is required"]})
            return;
        }
        let formData = new FormData();
        console.log(form?.file)
        formData.append("file", form?.file?.[0]);
        formData.append("name", form?.name);
        formData.append("owner", form?.owner);
        formData.append("is_hidden", form?.is_hidden);
        axiosInstance.post(URLS.ReportFileCrud, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setFormData({
                name: "",
                is_hidden: false,
                owner: owner,
                file: null
            })
            if (refresh) refresh()
        }).catch((err) => {
            console.log("hello=?", err?.response?.data);
            if (err.response.status === 400 || err.request.status === 406) {
                actions.setError(err?.response?.data?.error || err?.response?.data);
            } else toast.error("Something went wrong")

        })
    }

    useEffect(() => {
        if (error?.non_field_errors) toast(error?.non_field_errors[0], {
            type: "error", toastId: "create-directory-error"
        })
    }, [error]);

    return (<MenuDropdown
            button={<button className={"primary-button-color whitespace-nowrap px-4 rounded-md py-1"}>Upload
                File</button>}
            classNames={"py-2 top-7 -left-[172px] md:right-[12px] w-max"}
        >

            <div className={"bg-white  rounded-md  shadow-xl dark:bg-darkBgSecondary"}>
                <div className={"flex flex-col gap-2"}>
                    <input type={"file"} id={"file"}
                           onChange={(e) => setFormData({file: e.target.files})}
                           hidden={true}/>
                    <label htmlFor={"file"}
                           className={"rounded-md dark:text-white flex flex-col justify-center items-center border-dashed border-[1px]  p-4"}>
                        <AiFillCloud/>
                        <p className={"text-xs text-gray-500 font-bold dark:text-gray-400"}>{
                            !form?.file ? "Upload File" : form?.file?.[0]?.name
                        }</p>

                    </label>
                    <input type="text"
                           value={form?.name}
                           onChange={(e) => setFormData({name: e.target.value})}
                           className={"bg-transparent mx-4   focus:ring-0 outline-none ring-0 text-black dark:text-white"}
                           placeholder={"File Name (optional)"}/>

                    <div className={"flex flex-row gap-2 pl-3"}>
                        <input type={"checkbox"}
                               onChange={(e) => setFormData({is_hidden: e.target.checked})}
                               checked={form?.is_hidden}
                        />
                        <p className={"text-xs text-gray-500 dark:text-gray-400"}>Hidden</p>
                    </div>
                    <FormNoneFieldErrors
                        errors={error?.non_field_errors}/>

                    <button
                        onClick={createDirectory}
                        className={"text-black dark:text-white dark:border-gray-700 border-t-[1px] whitespace-nowrap px-4  py-1"}>Create
                    </button>
                </div>
            </div>

        </MenuDropdown>

    )
}
