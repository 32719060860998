import {Route, Routes} from "react-router-dom";
import RecentVitals from "./RecentVitals";

export default function VitalEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<RecentVitals className={className} {...props} />}/>
        </Routes>

    )
}
