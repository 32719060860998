import {Route, Routes} from "react-router-dom";
import PatientList from "./patient-list/patient-list";
import PatientProfile from "./patient-profile/patient-profile";
import AddUpdatePatient from "./add-update-patient/add-update-patient";
import Notes from "./notes/notes";

export default function PatientEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<PatientList className={className} {...props} />}/>
            <Route path="/add" element={<AddUpdatePatient className={className} {...props} />}/>
            <Route path="/notes" element={<Notes className={className}   />}/>
            <Route path="/update/:id" element={<AddUpdatePatient className={className} {...props} />}/>
            <Route path="/:id" element={<PatientProfile className={className} {...props} />}/>
        </Routes>

    )
}
