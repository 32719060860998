import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {useEffect, useState} from "react";
import UploadFile from "../../../API/UploadFile";
import DpCircle from "../../../components/avatar/dp/DpCircle";
import {getUri, truchateString} from "../../../utils/utils";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";
import {BsCloudDownload} from "react-icons/bs";
import DoctorReportModal from "../../../Modals/doctor-report-modal/doctor-report-modal";

export default function AddUpdateDoctor({className, ...props}) {
    let {id} = useParams();
    const [form, error, loading, setForm, actions] = useForm({
        user: {
            full_name: "",
            email: "",
            date_of_birth: new Date().toISOString().split("T")[0],
            cnic: "",
            martial_status: "SINGLE",
            gender: "MALE",
            address: "",
        },
        documents: null,
        speciality: "",
        contact: ""
    });
    const navigation = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.DoctorsCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setForm(
                {
                    ...data,
                    user: {
                        ...data.user,
                        date_of_birth: new Date(data.user.date_of_birth).toISOString().split("T")[0]
                    }
                }
            )
        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
            navigation("/app/doctors")
        }).finally(() => {
            setLoadingData(false);
        })

    }

    useEffect(() => {
        if (id) {
            getFormData()
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        if (id) {
            actions.put(URLS.DoctorsCrud, (data) => {
                return {
                    ...data,
                    user: {
                        ...data.user,
                        dp: data.user.dp?.id,
                        date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD")
                    },
                    documents: data.documents?.id

                }
            }).then(res => {
                toast.success("Patient Updated Successfully")
                navigation("/app/doctor")
            }).catch(err => {
                console.log(err);
            })
            return;
        }


        actions.post(URLS.DoctorsCrud, (data) => {
            return {
                ...data,
                user: {
                    ...data.user,
                    dp: data.user.dp?.id,
                    date_of_birth: moment(new Date(data?.user.date_of_birth)).format("YYYY-MM-DD")
                },
                documents: data.documents?.id
            }
        }).then(res => {
            toast.success("Doctor Added Successfully")
            navigation("/app/doctor")
        }).catch(err => {
            console.log(err);
        })

    }

    if (loadingData)
        return <PageLoading/>

    return (
        <form onSubmit={handleSubmit}
              className={`w-full flex flex-col h-full overflow-auto  pb-20 mt-2 xl:px-10 ${className}`}>
            <DoctorReportModal
                doctorId={id}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
            <div className={"flex flex-row justify-between items-center gap-2"}>

                <PageTitle>
                    {
                        id ? "Update Doctor" : "Add Doctor"
                    }
                </PageTitle>

                {/*<div*/}
                {/*    title={"download report"}*/}
                {/*    className={"w-10 h-10 cursor-pointer rounded-full justify-center items-center flex  text-white bg-brand-800"}*/}
                {/*>*/}
                {/*    <BsCloudDownload*/}
                {/*        onClick={() => {*/}
                {/*            setIsModalOpen(true)*/}
                {/*        }}*/}
                {/*        className={"text-base"}/>*/}
                {/*</div>*/}
            </div>


            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    Information
                </PageTitle>
                <div className={"flex justify-center mb-4 items-center lg:col-span-2"}>
                    <DpCircle
                        src={getUri(form?.user?.dp?.file)}
                        onChange={(dp) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    dp: dp
                                }
                            })
                        }}
                    />
                </div>
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4"}>
                    <FormItem
                        label={"Full Name"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.user?.full_name}
                    >
                        <input
                            value={form?.user?.full_name}
                            placeholder={"Jhone"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        full_name: e.target.value

                                    }
                                })
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Email"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.email}
                    >
                        <input
                            value={form?.user?.email}
                            placeholder={"example@gmail.com"}
                            min={0}
                            required={true}

                            type={"email"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        email: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>
                    <div className={"grid grid-cols-1 gap-2"}>

                        <FormItem
                            label={"Date of Birth"}
                            type={"error"}
                            variant={"auth"}
                            message={error?.user?.date_of_birth}
                        >
                            <input
                                value={form?.user?.date_of_birth}
                                required={true}
                                type={"date"}

                                onChange={(e) => {


                                    setForm({
                                        ...form, user: {
                                            ...form.user,
                                            date_of_birth: e.target.value
                                        }
                                    })
                                }}
                            />
                        </FormItem>
                    </div>
                    <FormItem
                        label={"Gender"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.gender}
                    >
                        <select
                            value={form?.user?.gender}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        gender: e.target.value
                                    }
                                })
                            }}
                        >
                            <option className={"text-base"} value={"MALE"}>Male</option>
                            <option className={"text-base"} value={"FEMALE"}>Female</option>
                            <option className={"text-base"} value={"OTHER"}>Other</option>
                        </select>
                    </FormItem>


                    <FormItem
                        label={"Marital Status"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.martial_status}
                    >
                        <select
                            value={form?.user?.martial_status}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        martial_status: e.target.value
                                    }
                                })
                            }}
                        >
                            <option className={"text-base"} value={"SINGLE"}>Single</option>
                            <option className={"text-base"} value={"MARRIED"}>Married</option>
                            <option className={"text-base"} value={"OTHER"}>Other</option>
                        </select>
                    </FormItem>

                    <FormItem
                        label={"Speciality"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.speciality}
                    >
                        <input
                            value={form?.speciality}
                            placeholder={"Speciality"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, speciality: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Address"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.address}
                    >
                        <input
                            value={form?.user?.address}
                            placeholder={"address"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, user: {...form.user, address: e.target.value}})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Document"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.documents}
                    >
                        <label
                            htmlFor={"file"}
                            className={"w-full justify-center cursor-pointer items-center font-bold"}
                        >
                            {
                                form?.documents?.name ? truchateString(form?.documents?.name, 50) : "  Click to Upload Document"
                            }

                        </label>
                    </FormItem>
                    <input
                        type={"file"}
                        id={"file"}
                        accept={"application/pdf"}
                        hidden={true}
                        onChange={(e) => {
                            UploadFile(e.target.files[0]).then(res => {
                                console.log(res?.data)

                                setForm({
                                    ...form, documents: res?.data
                                })

                            }).catch(err => {
                                toast.error(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
                            })
                        }}
                    />
                    <FormItem
                        label={"Contact"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.contact}
                    >
                        <input
                            value={form?.contact}
                            placeholder={"contact"}
                            required={true}
                            type={"text"}
                            pattern={"^\+234\d{10}$"}
                            title={"Contact should be in +234xxxxxxxxxx format"}
                            onChange={(e) => {
                                setForm({...form, contact: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Password"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.user?.password}
                    >
                        <input
                            value={form?.user?.password}
                            placeholder={"password"}
                            autoComplete={"new-password"}

                            type={"password"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        password: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>
                </div>

                <FormButton
                    loading={loading}
                    type={"submit"} className={" mt-8 w-[500px] m-auto self-center "}>
                    Submit
                </FormButton>
            </FormCard>


        </form>

    )
}
