import {useEffect, useState} from "react";
import axiosInstance from "../API/Axios/AxiosConfig";
import {toast} from "react-toastify";
import URLS from "../API/Axios/URLS";


export default function useListApi(url, filters = {}, callback = null) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState(filters);


    function load(pageNumber = 1, pageSize = 10) {
        if (isLoading) return;
        setIsLoading(true)
        axiosInstance.get(url, {
            params: {
                page: pageNumber,
                page_size: pageSize,
                ...filter,
            }
        }).then(res => {
            setData(res.data)
            if (callback) {

                callback()
            }
        }).catch(err => {
            toast.error(err.response?.data?.detail || "Something went wrong", {
                toastId: "useListApi"
            });
        }).finally(() => {
            setIsLoading(false)
        })
    }

    function loadNextPage() {
        if (data?.next) {
            load(data?.currentPage + 1)
        }
    }

    function loadPrevPage() {
        if (data?.previous) {
            load(data?.currentPage - 1)
        }
    }

    function loadSpecificPage(pageNumber) {
        if (pageNumber < 1 || pageNumber > data?.totalPages)
            return;
        load(pageNumber)
    }


    function updateFilter(filter) {
        setFilter((old) => {
            return {
                ...old,
                ...filter
            }
        })
    }

    useEffect(() => {
        // if filter have keys then load
        if (Object.keys(filter).length > 0) {
            load()
        }
    }, [filter]);

    let actions = {
        load,
        loadNextPage,
        loadPrevPage,
        loadSpecificPage,
        updateFilter,
        reload: () => load(),
        refresh: () => load()
    }

    useEffect(() => {
        load()
    }, [url])

    return (
        [data, filter, isLoading, actions]
    )


}
