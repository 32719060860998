import React, { useEffect, useState, useContext } from "react";
import { AppointmentPicker } from "react-appointment-picker";

import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import "../../dates.css"

import {Container, Row, Col, Button} from "react-bootstrap";
import { getDay } from "date-fns";
import useForm from "../../Hooks/useForm";
import URLS from "../../API/Axios/URLS";
import DoctorsModal from "../../Modals/doctor-slection-modal/doctor-selction-modal";
import {BiArrowBack} from "react-icons/bi";
import {AiOutlineAppstoreAdd} from "react-icons/ai";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import PaymentVerificationModal from "./AppointmentList/PaymentVerificationModal";

function getFormattedDate(date) {
//      Mon Jun 03 2024 : inputDate
//      2024-06-03 : outputDate
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return year + "-" + month + "-" + day;
}

function SquareSelectable({ slot, onClick, selected }) {
    return (
        // make the div circular and add a border if selected

        <div
            onClick={onClick}
            className={`border-2  m-1 py-6 mt-2 text-center items-center justify-center dark:text-white  cursor-pointer px-4  
            ${
                selected ? " border-brand-500 text-white bg-brand-500 " : " bg-transparent text-black "
            }
            rounded-xl hover:border-brand-500 `}



        >
            <div className={" flex flex-1 w-full h-full"}>
                <p className={"self-center text-center "}> {slot}</p>
            </div>
        </div>
    );

}

function Slot({ slot, onClick, selected }) {
    return (
        // make the div circular and add a border if selected

        <div
            onClick={onClick}
            className={`border-2 w-24 h-24 m-1 text-center items-center justify-center dark:text-white  cursor-pointer px-4  
            ${
                selected ? " border-brand-500 text-white bg-brand-500 " : " bg-transparent text-black "
            }
            rounded-full hover:border-brand-500 `}



        >
            <div className={" flex flex-1 w-full h-full"}>
                <p className={"self-center text-center "}> {slot}</p>
            </div>
        </div>
    );

}

function AppointmentsComp() {

    const [date, setDate] = useState();
    const [days, setDays] = useState([[]]);
    const [appointment, setAppointment] = useState("");
    // disable the past dates
       const modifiers = {
        disabled: (date) => {
            return date < new Date().setDate(new Date().getDate() - 1)
        },
           active: (date) => {
                return getDay(date) === 0 || getDay(date) === 6
           }
       }
       //
       const modifiersClassNames = {
       }
    const [response, setResponse] = useState();
    const [slots,setSlots] = useState([])
    const [form, error, loading, setFormData, actions] = useForm()
    useEffect(() => {
        if (date != null) {
        //     display the day ie Mon, Tue, Wed, Thu, Fri, Sat, Sun
            setFormData({ day: date.toString().split(" ")[0],
                doctor_id: appointment?.doctor_id,
                date:date,
                getSlots:true
            })

        }

    }, [date]);
    useEffect(() => {
        if(form && form?.getSlots){
            actions.post(URLS.getAvailableSlots).then(res => {
                setSlots(res?.data.data)
            })
        }

        else if(form && form?.doctor_id && !form?.getSlots){
            actions.post(URLS.saveAppointment).then(res => {
                if(res.data?.status===200){
                    toast.success("Appointment booked successfully")
                    setAppointment(null)
                    setDate(null)
                    setSlots([])
                    let payment = res.data?.payment
                    if(payment){
                        setIsPaymentModalOpen(true)
                        setResponse(res.data)
                    let url = payment.data.authorization_url
                    //     open in a new tab
                    window.open(url, "_blank")
                    }
                }
                else{
                    console.log(res?.data?.message)
                }

            })
        }
    }, [form]);
    useEffect(() => {
    //     scroll to the slot picker
        if(slots && appointment?.doctor_id && date){
            document.getElementById("slot-picker").scrollIntoView(
                { behavior: "smooth" }
            );
        }
    }, [slots]);
    useEffect(() => {
    //     scroll to the slot picker
        if(appointment?.time){
            document.getElementById("callType-picker").scrollIntoView(
                { behavior: "smooth" }
            );
        }
    }, [appointment?.time]);
    useEffect(() => {
        if(appointment){
            document.getElementById("calender").scrollIntoView(
                { behavior: "smooth",
                    block:"center",
                    position:"center"
                }
            );
        }

    }, [appointment?.doctor_id]);


    const [isOpen, setIsOpen] = useState(false)
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
    return (
        <div >
            <Container fluid={"sm"}>
                <DoctorsModal
                    isOpen={isOpen}
                    onSelect={(data) => {
                        setSelectedDoctor(data)
                        setAppointment({
                            doctor_id: data.id
                        })
                        setIsOpen(false)
                    }}
                    setIsOpen={setIsOpen}
                    title={"Select Doctor"}
                />
                <PaymentVerificationModal
                    isOpen={isPaymentModalOpen}
                    response={response}
                    title={"Payment Verification"}
                    setIsOpen={setIsPaymentModalOpen}
                />
                <div className={"flex justify-between items-center"}>
                    <h1 className={"text-3xl font-semibold text-gray-800 dark:text-gray-100 text-center "}
                    id={"appointment-header"}
                    >Book an Appointment</h1>
                    <div>

                        <Link title={"Go Back"}
                              className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                              to={"/app/appointments"}>
                            <BiArrowBack className={"text-base"}/>

                        </Link>
                    </div>
                </div>


                <div className={"mt-60"}>
                    <h1
                        onClick={()=>{
                            setIsOpen(true)
                        }}
                        className={"text-xl cursor-pointer font-semibold border p-4 rounded dark:bg-gray-700 border-gray-800 hover:border-brand-700 bg-white text-gray-800 dark:text-gray-100 text-center hover:bg-brand-500 hover:dark:bg-brand-500  hover:text-white" }>
                        {
                            appointment?.doctor_id ? selectedDoctor?.user?.full_name + " : Change Doctor" : "Select Doctor"
                        }
                    </h1>
                </div>


                {
                    appointment?.doctor_id &&
                    <Row className={"mt-60 border-t-2  "} id={"calender"}>
                        <Col >
                            <DatePickerCalendar
                                modifiers={modifiers}
                                date={date}
                                modifiersClassNames={modifiersClassNames}
                                onDateChange={(date)=>{
                                //    add 8 hours to the date
                                    setDate(new Date(date.setHours(8)))

                                }}
                            />
                        </Col>

                    </Row>}
                <div
                    id={"slot-picker"}
                    className={"flex flex-col mt-60 border-t-2 py-60"}>
                    {
                        slots?.length > 0 ?
                            <div>
                                <h3 className={
                                    "text-2xl font-semibold text-gray-800 dark:text-gray-100 text-center "
                                }>Select From Available Slots</h3>


                                <div className={"flex flex-wrap justify-center"}>
                                    {
                                        slots?.map((slot) => {
                                            return (
                                                <Slot
                                                    onClick={() => {
                                                        setAppointment({
                                                            ...appointment,
                                                            day: date.toString().split(" ")[0],
                                                            time: slot,
                                                            date: date?.toDateString(),
                                                        })
                                                    }}
                                                    selected={appointment?.time === slot && appointment?.date === date?.toDateString()}
                                                    slot={slot}
                                                />
                                            )
                                        })
                                    }
                                </div>

                            </div>

                            :
                            <p className={" my-8 py-8 w-full text-center text-xl font-medium text-gray-800 dark:text-gray-100"}>
                                {!date ? "Select a Doctor & a date to view available slots" : "No slots available for this date"}

                            </p>
                    }
                </div>

                <div className={" border-t-2 py-60 mt-60 "}>
                    {
                        appointment?.time && <div className={"w-full flex "}>
                            <div className={"w-1/3 flex flex-col "}
                                 id={"callType-picker"}
                            >
                                <h3 className={
                                    "text-2xl text-center font-semibold text-gray-800 dark:text-gray-100 "
                                }>Selected Slot</h3>
                                <div className={"flex flex-wrap items-center justify-center"}>
                                    <Slot
                                        onClick={() => {
                                            setAppointment(null)
                                            setDate(null)
                                            setSlots([])
                                        //     scroll to the top
                                            document.getElementById("appointment-header").scrollIntoView(
                                                { behavior: "smooth",
                                                    block:"start",
                                                }
                                            );
                                        }}

                                        selected={true}
                                        slot={appointment?.time}

                                    />
                                    <Slot
                                        onClick={() => {
                                            setAppointment(null)
                                            setSlots([])
                                            setDate(null)
                                            document.getElementById("appointment-header").scrollIntoView(
                                                { behavior: "smooth",
                                                    block:"start",
                                                }
                                            );
                                        }}
                                        selected={true}
                                        slot={appointment?.date}
                                    />


                                </div>
                            </div>

                            <div className={"w-1/3 border-l-2 pl-8"}>
                                <h3 className={
                                    "text-2xl text-center font-semibold text-gray-800 dark:text-gray-100 "
                                }>Selected Conference Type</h3>
                                <div className={"flex flex-1 items-center justify-center"}>
                                    <SquareSelectable
                                        onClick={() => {
                                            setAppointment({
                                                ...appointment,
                                                media: "VIDEO CALL"
                                            })
                                        }}

                                        selected={appointment?.media === "VIDEO CALL"}
                                        slot={"VIDEO CALL | 3500 NGN"}
                                    />
                                    <SquareSelectable
                                        onClick={() => {
                                            setAppointment({
                                                ...appointment,
                                                media: "AUDIO CALL"
                                            })
                                        }}

                                        selected={appointment?.media === "AUDIO CALL"}
                                        slot={"AUDIO CALL | 3500 NGN"}
                                    />

                                </div>
                            </div>

                            {
                                appointment?.media && <div className={"w-1/3 border-l-2 pl-8"}>
                                    <h3 className={
                                        "text-2xl text-center font-semibold text-gray-800 dark:text-gray-100 "
                                    }>Selected Call Type</h3>
                                    <div className={"flex flex-1 items-center justify-center "}>
                                        <SquareSelectable
                                            onClick={() => {
                                                setAppointment({
                                                    ...appointment,
                                                    call_type: "IMMEDIATE"
                                                })
                                            }}

                                            selected={appointment?.call_type === "IMMEDIATE"}
                                            slot={"IMMEDIATE"}
                                        />
                                        <SquareSelectable
                                            onClick={() => {
                                                setAppointment({
                                                    ...appointment,
                                                    call_type: "SCHEDULED"
                                                })
                                            }}

                                            selected={appointment?.call_type === "SCHEDULED"}
                                            slot={"SCHEDULED"}
                                        />

                                    </div>
                                </div>
                            }


                        </div>
                    }
                    {
                        appointment && appointment?.media && appointment?.call_type &&
                        <div className={"w-full  mt-40 flex flex-1 justify-center items-center"}>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                   //  date should be in the format yyyy-mm-dd

                                   setFormData({
                                        ...appointment,
                                       date:getFormattedDate(date),
                                        getSlots:false
                                   })
                                }}
                                className={"bg-brand-500 hover:bg-brand-700 text-white p-4 rounded border border-transparent hover:border-white disabled:opacity-55 "}>
                                Book Appointment
                            </Button>
                        </div>
                    }

                </div>



            </Container>
        </div>

    );
}

export default AppointmentsComp;
