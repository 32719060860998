import AdminLayout from "../layout/admin/admin";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Properties from "./Properties";
import DocumentManager from "./document-manager/document-manager";
import PatientEntry from "./patient/patient";
import Calls from "./calls/calls";
import Appointment from "./Appointments/Appointment";
import DoctorEntry from "./doctor/doctor";
import AppointmentEntry from "./Appointments/AppointmentEntry";
import ScheduleEntry from "./Scheduling/ScheduleEntry";
import PatientCall from "./PatientCall/calls";
import CallEntry from "./calls/CallEntry";
import SettingIndex from "./Setting";
import DashboardDoctor from "./dashboardDoctor/Dashboard";
import VitalEntry from "./Vitals/Entry";
import ScheduleAdminEntry from "./AdminScheduling/ScheduleEntry";
import AppointmentAdminEntry from "./AppointmentsAdmin/AppointmentEntry";
import PaymentsEntry from "./Payments/PaymentsEntry";
import routes from "../utils/Routes/Sidebar";
import {useSession} from "../redux/Reducers/AuthReducer";
import Notes from "./patient/notes/notes";
import DoctorProfile from "./doctor/doctor-profile/doctor-profile";
import AddUpdateDoctor from "./doctor/add-update-doctor/add-update-doctor";


export default function DemoScreenEntry({className, ...props}) {
    const {authActions} = useSession();

    return (
        <AdminLayout>
            <Routes>
                {
                    routes.map((route, index) => {
                        if (authActions.contains_role(route?.role))
                            return (
                                <Route key={index} path={route.path + "/*"} element={<route.component/>}/>
                            )
                        return null

                    })
                }

                {

                    authActions.contains_role(["admin", "doctor"]) && (

                        <Route path="/app/document-manager/:email" element={<DocumentManager/>}/>)
                }
                <Route path="/app/patient/notes" element={<Notes className={className}   />}/>

                {/*<Route path="/app/dashboard" element={<Dashboard/>}/>*/}
                {/*<Route path="/app/dashboard-doctor" element={<DashboardDoctor/>}/>*/}
                {/*<Route path="/app/document-manager" element={<DocumentManager/>}/>*/}
                {/*<Route path="/app/patient/*" element={<PatientEntry/>}/>*/}
                {/*<Route path="/app/payments/*" element={<PaymentsEntry/>}/>*/}
                {/*<Route path="/app/doctor/*" element={<DoctorEntry/>}/>*/}
                {/*<Route path="/app/calls/*" element={<CallEntry/>}/>*/}
                {/*<Route path="/app/appointments/*" element={<AppointmentEntry/>}/>*/}
                {/*<Route path="/app/appointments-admin/*" element={<AppointmentAdminEntry/>}/>*/}
                {/*<Route path="/app/schedule/*" element={<ScheduleEntry/>}/>*/}
                {/*<Route path="/app/settings/*" element={<SettingIndex/>}/>*/}


                <Route path="/app/schedule-admin/*" element={<ScheduleAdminEntry/>}/>
                <Route path="/app/patient-call/:id" element={<PatientCall/>}/>
                {authActions.is_admin() &&
                    <Route path="/app/doctors/add" element={<AddUpdateDoctor className={className} {...props} />} /> }
                {authActions.is_admin() &&
                    <Route path="/app/doctors/update/:id" element={<AddUpdateDoctor className={className} {...props} />} /> }
                <Route path="/app/doctor/:id" element={<DoctorProfile className={className} {...props} />}/>
                <Route path="/app/vitals/*" element={<VitalEntry/>}/>

            </Routes>
        </AdminLayout>

    )
}
