import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import {useSession} from "../../../redux/Reducers/AuthReducer";
import SearchInput from "../../../components/fields/search-input/search-input";
import {Link} from "react-router-dom";
import {AiFillFilter, AiOutlineAppstoreAdd} from "react-icons/ai";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import TableContainer from "../../../components/Table/TableContainer";
import PaymentCalenderModal from "../../../Modals/payment-calender-modal/payment-calender-modal";
import {useEffect, useState} from "react";
import {BsCloudDownload} from "react-icons/bs";


export default function PaymentTable({className,refresh,setRefresh, ...props}) {
    let [data, filter, isLoading, paymentActions] = useListApi(URLS.PaymentList, {
        search: "",
    });
    const {authActions} = useSession();
    const [isModal, setIsModal] = useState(false);

    const onDateRangeChange = (date) => {
        if (!date) return;
        paymentActions.updateFilter({
            ...date
        })
    }

    useEffect(() => {
        if (refresh) {
            paymentActions.refresh()
            setRefresh(false)
        }
    }, [refresh]);



    return (<div className={`w-full h-full overflow-auto ${className}`}>
            <PaymentCalenderModal
                isOpen={isModal}
                setIsOpen={setIsModal}
                onDateRangeChange={onDateRangeChange}

            />
            <div className={"w-full flex mb-10 gap-3 justify-end items-center"}>
                <div
                    onClick={() => {
                        setIsModal(true)
                    }}
                    className={"border-[1px] text cursor-pointer  rounded-md px-3 py-1 flex flex-col dark:text-white dark:opacity-70   "}>
                    <label className={"text-md mb-2"}>
                        Date Range
                    </label>
                    <div className={"grid text-sm grid-cols-2 gap-1"}>
                        <div className={"inline-flex gap-2"}>
                            <p className={"font-semibold"}>
                                From:
                            </p>
                            <p>
                                {filter?.start_date ? filter?.start_date : "Not set"} -
                            </p>

                        </div>
                        <div className={"inline-flex gap-2"}>

                            <p className={"font-semibold"}>
                                To:
                            </p>
                            <p className={"text-start"}>
                                {filter?.end_date ? filter?.end_date : "Not set"}
                            </p>
                        </div>
                    </div>

                </div>


                <div
                    className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                >
                    <BsCloudDownload
                        onClick={() => {
                            setIsModal(true)
                        }}
                        className={"text-base"}/>
                </div>


            </div>
            <div
                className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
            >
                {/*<SearchInput*/}
                {/*    hideRightIcon={true}*/}
                {/*    onChange={(e) => {*/}
                {/*        paymentActions.updateFilter({*/}
                {/*            search: e.target.value*/}
                {/*        })*/}
                {/*    }}*/}
                {/*    outerClassName={"border-[1px]  rounded-full"}*/}
                {/*    placeholder={"Search records"}*/}
                {/*/>*/}
            </div>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>id</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>status </TableCell>
                            <TableCell>Date</TableCell>

                            <TableCell>Payment Url</TableCell>
                            <TableCell>Payment Mode</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((record, i) => {
                            let user = record?.user;
                            return <TableRow

                                key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="font-semibold">{record?.appointment}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{record?.amount}</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{record?.reference}</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        record?.status}</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        record?.date}</span>
                                </TableCell>

                                <TableCell>
                                    {record?.payment_url && <a
                                        href={record?.payment_url}
                                        target={"_blank"}
                                        className={"text-blue-500 cursor-pointer underline"}
                                    >
                                        View Payment
                                    </a>}
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        record?.paymentType}</span>
                                </TableCell>

                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            paymentActions.loadNextPage()
                        }}
                        onPrev={(e) => paymentActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            paymentActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
