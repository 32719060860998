import {BASE_URL} from "../settings/settings";
import {saveAs} from 'file-saver'


export const getUri = (uri) => {
    const DEFAULT = "https://i.postimg.cc/6qf1gyq1/male-man-person-people-avatar-white-tone-icon-159365.png"


    try {
        if (uri === null || uri === undefined) {
            return DEFAULT
        }
        if (uri.startsWith("/")) {
            uri = uri.substring(1);
        }

        return uri.includes("http") ?
            uri : BASE_URL + uri

    } catch (E) {
        return DEFAULT
    }
}

export function isNoteAvailable(appointment) {
    return appointment?.chiefComplaints || appointment?.investigations || appointment?.medications || appointment?.treatment || appointment?.referral || appointment?.diseases
}

export function truchateString(string, length) {
    if (!string) return ""
    if (string.length > length) {
        return string.substring(0, length) + "..."
    }
    return string
}

export const PROPERTY_CHOICES = {
    AVAILABLE: 'available',
    SOLD: 'sold',
    UN_LISTED: 'unlisted',
}

export function getPropertyValue(key) {
    if (key in PROPERTY_CHOICES) {
        return PROPERTY_CHOICES[key]
    }

    return key

}


export function getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}


export function getColorForCallType(type, isDarkMode) {
    switch (type) {
        case "audio":
            return isDarkMode ? "#6690ff" : "#0a50ee"
        case "urgent":
            return isDarkMode ? "#ff0000" : "#ff0000"
        case "video":
            return isDarkMode ? "#ff8c00" : "#ff8c00"
        default:
            return "#ae08ff"
    }
}

const download = async (url) => {
    saveAs(url, url.split("/").pop())
};
export default download;


export function createNoteEmail(medication, symptoms) {
    return `Medication: 
${medication}
 
Symptoms: 
${symptoms}

`
}
