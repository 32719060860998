import Tabs from "../../../../components/tabs/tabs";
import AppointmentTypes from "../variables/appointment-types";
import dummyAppointment from "../../../../utils/dummy-data/dummy-appointment";
import {BiNote} from "react-icons/bi";
import {CgNotes} from "react-icons/cg";
import {useEffect, useState} from "react";
import useListApi from "../../../../Hooks/useListApi";
import URLS from "../../../../API/Axios/URLS";
import PageLoading from "../../../../components/Loaders/page-loading/page-loading";
import Spinner from "../../../../components/Loaders/Spinner/Spinner";
import NotesModal from "../../../../Modals/notes-modal/notes-modal";
import {useNavigate} from "react-router-dom";
import {isNoteAvailable} from "../../../../utils/utils";

function AppointmentRow({
                            date,
                            startTime,
                            endTime,
                            treatment,
                            media,
                            status,
                            index,
                            id, ...props
                        }) {


    return (
        <div className={"grid grid-cols-5 bg-white dark:bg-gray-800 gap-2 p-4 rounded-md"}>


            
            <div>
                <p className={"text-sm text-gray-500 dark:text-gray-400"}>Date</p>
                <p className={"text-sm text-gray-800 dark:text-gray-200"}>{date}</p>
            </div>
            <div>
                <p className={"text-sm text-gray-500 dark:text-gray-400"}>Time</p>
                <p className={"text-sm text-gray-800 dark:text-gray-200"}>{startTime} - {endTime}</p>
            </div>
            <div>
                <p className={"text-sm text-gray-500 dark:text-gray-400"}>Status</p>
                <p className={"text-sm text-gray-800 dark:text-gray-200"}>{status}</p>
            </div>
            <div>
                <p className={"text-sm text-gray-500 dark:text-gray-400"}>Mode</p>
                <p className={"text-sm text-gray-800 dark:text-gray-200"}>{media}</p>
            </div>
            {
                isNoteAvailable(props) &&
                <div
                onClick={() => props?.onNoteClick && props?.onNoteClick()}
                className={"flex flex-row items-center gap-4 text-2xl text-gray-500 dark:text-gray-400 cursor-pointer"}>
                <CgNotes className={""}/>

            </div>}
        </div>
    )
}

export default function Appointment({className, patientId,doctorId, ...props}) {
    const [status, setStatus] = useState("Upcoming")
    const [data, filter, isLoading, actions] = useListApi(URLS.patientAppointments, {
        patientId: patientId,
        doctorId: doctorId,
        status: status,
        page_size:100
    })
    const navigation = useNavigate();

    useEffect(() => {
        actions.updateFilter({
            status: status
        })
    }, [status]);


    console.log(data)

    function onNoteClick(appointment) {
        navigation(`/app/patient/notes`, {
            state: appointment
        })
    }

    return (
        <div className={` ${className} card p-4`}>
            <div className={"flex flex-col gap-4"}>
                <Tabs
                    items={AppointmentTypes}
                    onChange={(value) => {
                        setStatus(value?.value)
                    }}
                />
                <div className={"p-4 max-h-[40vh] overflow-auto"}>
                    {!isLoading ? <div className={"flex flex-col gap-5 bg-gray-200 dark:bg-opacity-5 rounded-md p-4 "}>
                        {
                            data?.results?.map((appointment, index) => {
                                return (
                                    <AppointmentRow
                                        key={index}
                                        index={index+1}
                                        onNoteClick={() => {
                                            onNoteClick(appointment)
                                        }}
                                        {...appointment}
                                    />
                                )
                            })
                        }
                    </div> : <div className={"flex justify-center items-center"}>
                        <Spinner/>
                    </div>
                    }
                </div>
            </div>
        </div>

    )
}
