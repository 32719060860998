import {Route, Routes} from "react-router-dom";

import DevicesList from "./list/DevicesList";
import AddUpdateDevice from "./add-update/DeviceAddUpdate";

export default function DevicesEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<DevicesList className={className} {...props} />}/>
            <Route path="/add" element={<AddUpdateDevice className={className} {...props} />}/>
            <Route path="/update/:id" element={<AddUpdateDevice className={className} {...props} />}/>

        </Routes>

    )
}
