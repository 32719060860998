import {Route, Routes} from "react-router-dom";
import Appointment from "./Appointment";
import AppointmentList from "./AppointmentList/AppointmentList";

export default function AppointmentAdminEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<AppointmentList className={className} {...props} />}/>
            <Route path="/add" element={<Appointment className={className} {...props} />}/>
        </Routes>

    )
}
