import SearchInput from "./SearchInput/SearchInput";
import ProfileMenu from "./menu/ProfileMenu";
import NotificationMenu from "./menu/NotificationMenu";
import NavigationDrawerButton from "../sidebar/NavigationDrawerButton";
import "./css/navbar.css"

export default function Navbar({className,...props}) {

     return (
         <div className={` ${className} z-10 py-4 flex flex-row bg-white shadow-sm justify-between items-center dark:bg-gray-800 pl-2 duration-300 ease-linear`}>
             <NavigationDrawerButton  className={"ml-2 mr-5"} />
             {/*<SearchInput  />*/}
             <div className={"flex items-center flex-shrink-0 space-x-6 mr-5 md:mr-10 lg:mr-14 xl:mr-20 "}>
                 {/*<NotificationMenu />*/}
                 <ProfileMenu />


             </div>
         </div>

     )
 }
