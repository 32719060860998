import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export  function checkDarkModePreference(){
    return localStorage.getItem("isDarkMode")? localStorage.getItem("isDarkMode") === "true" : window.matchMedia('(prefers-color-scheme: dark)').matches;
}


const initialState = {
    isDarkMode: checkDarkModePreference(),
}

export const THEME_ACTIONS = {
    T0GGL_THEME: "T0GGL_THEME",
    SWITCH_TO_DARK_MODE: "SWITCH_TO_DARK_MODE",
    SWITCH_TO_LIGHT_MODE: "SWITCH_TO_LIGHT_MODE"
}


const ThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case THEME_ACTIONS.T0GGL_THEME:
            if (state.isDarkMode) {
                document.body.classList.remove("dark");
            } else {
                document.body.classList.add("dark");
            }
            return {
                ...state,
                isDarkMode: !state.isDarkMode
            }
        case THEME_ACTIONS.SWITCH_TO_DARK_MODE:
            document.body.classList.add("dark");
            return {
                ...state,
                isDarkMode: true
            }
        case THEME_ACTIONS.SWITCH_TO_LIGHT_MODE:
            document.body.classList.remove("dark");
            return {
                ...state,
                isDarkMode: false
            }
        default:
            return state;
    }
}


export function useTheme() {
    const dispatch = useDispatch();
    const isDarkMode = useSelector(state => state.ThemeReducer.isDarkMode);

    useEffect(() => {
        localStorage.setItem("isDarkMode", isDarkMode);
    }, [isDarkMode])

    const toggleTheme = () => {
        dispatch({type: THEME_ACTIONS.T0GGL_THEME});
    }
    const actions = {
        toggleTheme,
        setToLightMode: () => dispatch({type: THEME_ACTIONS.SWITCH_TO_LIGHT_MODE}),
        setToDarkMode: () => dispatch({type: THEME_ACTIONS.SWITCH_TO_DARK_MODE})
    }

    return {isDarkMode, toggleTheme, actions}
}


export default ThemeReducer;