import PageTitle from "../../../components/utils/PageTitle";
import React from "react";
import PaymentCard from "./PaymentCard";


export default function Payments({className, ...props}) {

    return (
        <div className={` ${className}`}>
            <PageTitle>
                Payments
            </PageTitle>

            <div className="mt-6">
                <PaymentCard refresh={props?.refresh} setRefresh={props?.setRefresh} />
            </div>
        </div>

        )
}
