import {FaPhone} from "react-icons/fa";
import {MdMic} from "react-icons/md";
import {BiPause, BiSolidCamera, BiSolidCameraOff} from "react-icons/bi";
import AgoraRTC, {AgoraRTCProvider, useRTCClient} from "agora-rtc-react";
import {StrictMode, useState} from "react";
import config from "./config";
import Call from "./Call";


export default function CallDetails({className,...props}) {
    const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: config.selectedProduct }));
    agoraEngine.enableAudioVolumeIndicator()

    const [joined, setJoined] = useState(true);
    const [cameraAllowed, setCameraAllowed] = useState(true);

     return (
         <div className={"card p-2 border-[1px] border-purple-800 dark:border-purple-500 md:flex-1"}>
             <div className={"flex-col-center gap-7 my-10"}>
                 <p className={"opacity-60 dark:text-white "}>
                     {/*{ agoraEngine.getRTCStats().Duration? `Call Duration: ${agoraEngine.getRTCStats().Duration}`: "Call Duration: 00:00"}*/}
                 </p>
                 {joined && (
                     <StrictMode>
                         <AgoraRTCProvider client={agoraEngine}>
                             <Call />

                             {/*<AgoraManager*/}
                             {/*    cameraAllowed={cameraAllowed}*/}
                             {/*    config={config}  children={*/}
                             {/*    <></>*/}
                             {/*}>*/}
                             {/*</AgoraManager>*/}
                         </AgoraRTCProvider>
                     </StrictMode>
                 )}
                 {/*<div className={"flex flex-row gap-[12rem]"}>*/}
                 {/*    <div className={"flex-col-center gap-1"}>*/}
                 {/*        <img src={"https://randomuser.me/api/portraits/men/20.jpg"} alt={"asd"}*/}
                 {/*             className={"w-24 h-24  shadow-sm rounded-full"}/>*/}
                 {/*        <p className={"text-xl dark:text-white"}>*/}
                 {/*            Hamza Shahzad*/}
                 {/*        </p>*/}
                 {/*        <p className={"text-sm opacity-60 dark:text-white"}>*/}
                 {/*            Doctor*/}
                 {/*        </p>*/}
                 {/*    </div>*/}
                 {/*    <div className={"flex-col-center gap-2"}>*/}
                 {/*        <img src={"https://randomuser.me/api/portraits/women/29.jpg"} alt={"asd"}*/}
                 {/*             className={"w-24 h-24 shadow-sm  rounded-full"}/>*/}
                 {/*        <p className={"text-xl dark:text-white"}>*/}
                 {/*            Ayesha Shahzad*/}
                 {/*        </p>*/}
                 {/*        <p className={"text-sm opacity-60 dark:text-white"}>*/}
                 {/*            Patient*/}
                 {/*        </p>*/}
                 {/*    </div>*/}
                 {/*</div>*/}



             </div>

         </div>

     )
}
