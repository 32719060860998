import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";
import PageTitle from "../../../components/utils/PageTitle";
import PatientDetailCard from "../../patient/patient-profile/component/detail-card";
import DoctorDetailCard from "./components/detail-card";
import DoctorReportModal from "../../../Modals/doctor-report-modal/doctor-report-modal";
 import Appointment from "../../patient/patient-profile/component/appointment";
import DoctorAppointmentCard from "./components/doctor-appointment-card";
import {useSession} from "../../../redux/Reducers/AuthReducer";

export default function DoctorProfile({className, ...props}) {
    const {authActions} = useSession();
    const isPatient = authActions.is_patient()
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const navigation = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);


    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.DoctorsCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setData(
                {
                    ...data,
                    user: {
                        ...data.user,
                        date_of_birth: moment(data.user.date_of_birth).format("YYYY-MM-DD")
                    }
                }
            )
            setLoadingData(false);

        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong", {
                toastId: "doctor-profile-error",
            })
            navigation("/app/doctor")
        })

    }

    useEffect(() => {
        getFormData()
    }, [id])

    if (loadingData)
        return <PageLoading/>
    return (
        <div className={` ${className} pb-20`}>
            <DoctorReportModal
                doctorId={id}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />

            <div className={"flex flex-row justify-center items-center gap-2"}>
                <PageTitle className={"flex-1"}>
                    {
                        data?.user?.full_name
                    }
                </PageTitle>

                {!isPatient && <div>
                    <div
                        title={"download report"}
                        onClick={() => {
                            setIsModalOpen(true)
                        }}
                        className={" cursor-pointer px-4 hover:opacity-90 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                    >
                        Download Report
                    </div>
                </div>}

            </div>
            <div className={`${!isPatient?  "grid grid-cols-1 md:grid-cols-2":"flex flex-1"}  gap-4`}>
                <div className={"w-full"}>
                    <DoctorDetailCard
                        isPatient={isPatient}

                        data={data}
                    />


                </div>
                { !isPatient && <DoctorAppointmentCard id={id} />}
                {!isPatient && <div className={"md:col-span-2   "}>
                    <Appointment doctorId={id}/>

                </div> }
            </div>

        </div>

    )
}
