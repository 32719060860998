import PageTitle from "../../../components/utils/PageTitle";
import DevicesTable from "./components/Table";


export default function DevicesList({className, ...props}) {

        return (
            <div className={` ${className}`}>
                <PageTitle>
                    Devices
                </PageTitle>
                <div className={"flex flex-col gap-5"}>
                    <DevicesTable/>

                </div>
            </div>

        )
    }
