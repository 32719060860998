import SidebarMobile from "./SidebarMobile";
import SidebarDesktop from "./SidebarDesktop";
import {useTheme} from "../../redux/Reducers/ThemeReducer";
import logo from "../../assets/img/logo.png";
import routes from "../../utils/Routes/Sidebar";
import {NavLink} from 'react-router-dom'
import {useLocation} from "react-router-dom";
import SidebarSubMenu from "./SidebarSubMenu";
import {useSession} from "../../redux/Reducers/AuthReducer";

function SidebarBody({className, ...props}) {

    return (
        <>
            <SidebarMobile>
                {props.children}
            </SidebarMobile>
            <SidebarDesktop>
                {props.children}
            </SidebarDesktop>
        </>

    )
}

export default function Sidebar({className, ...props}) {
    const {isDarkMode} = useTheme();
    const filterStyles = !isDarkMode ? {filter: 'brightness(1) invert(0)'} : {filter: 'brightness(0) invert(1)'};
    const location = useLocation();
    const {authActions} = useSession();


    return (
        <SidebarBody>
            <div className={"flex mb-8 mt-[2rem] justify-center "}>
                <img alt={"logo"} style={filterStyles} src={logo} className={"h-5"}/>
            </div>

            <ul className="mt-6">
                { routes.map((route) =>
                    authActions.contains_role(route?.role) && (
                        <li className="relative px-6 py-3" key={route.name}>
                            <NavLink
                                exact
                                to={route.path}
                                className="inline-flex text-gray-800 hover:text-gray-500 dark:text-gray-100 dark:hover:text-gray-300 items-center w-full text-sm font-semibold  duration-150 "
                            >
                                {
                                    location.pathname.includes(route.path) ? <span
                                        className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                                        aria-hidden="true"
                                    /> : null
                                }


                                {
                                    route.icon ? <route.icon className={`${route?.iconClassName} text-xl `}/> : null
                                }
                                <span className="ml-4 ">{route.name}</span>
                            </NavLink>
                        </li>
                    )
                )}
            </ul>

        </SidebarBody>

    )
}
