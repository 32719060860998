import {CiCamera} from "react-icons/ci";
import {useSession} from "../../../redux/Reducers/AuthReducer";
import UploadFile from "../../../API/UploadFile";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {FaCamera, FaFileUpload} from "react-icons/fa";


export default function DpCircle({className, dpClassName, src, onChange, ...props}) {

    function handleDpChange(e) {
        UploadFile(e.target.files[0]).then((res) => {
            if (onChange)
                onChange(res.data)
        }).catch((err) => {
            toast("Error uploading  picture", {
                type: "error"
            })
        })
    }

    return (
        <div className={"flex "}>
            <input hidden={true} id={"dp"} type={"file"} accept={"image/*"} onChange={handleDpChange}/>
            <div className={`relative  ${className}`}>
                <img alt={"profile image"} src={src ? src : "/icons/user-icon.svg"}
                     className={` ${dpClassName} w-16 rounded-full shadow-md lg:w-24 h-16 lg:h-24`}/>
                <label
                    htmlFor={"dp"}
                    className={" absolute cursor-pointer  bottom-2 right-0 w-6 h-6 rounded-full justify-center items-center flex bg-tertiary-900"}>
                    <FaCamera  className={"text-purple-900 text-xl"}/>
                </label>
            </div>

        </div>

    )
}