

 export default function TableCell({className,...props}) {

     return (
         <td className={`px-4 py-3 whitespace-no-wrap ${className}`} {...props}>
             {
                    props.children
             }
         </td>

     )
 }