const variants = {
    base: " px-3 py-2 rounded-md cursor-pointer flex-1 flex justify-center items-center  dark:text-white",
    active: "bg-purple-500 text-white ",
    default: "bg-white dark:bg-gray-800 dark:text-white",


}


export default function TabItem({className, variant = "default", ...props}) {

    return (
        <div
            onClick={props.onClick}
            className={` ${className} ${variants[variant]} ${variants.base} whitespace-nowrap`}>
            {
                props.children
            }
        </div>

    )
}