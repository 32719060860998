import DpCircle from "./components/DpCircle";
import {GiPositionMarker} from "react-icons/gi";
import React from "react";
import {TfiMarkerAlt} from "react-icons/tfi";

import {FcGoogle} from "react-icons/fc";
import {Link} from "react-router-dom";

import {useSession} from "../../redux/Reducers/AuthReducer";
import {getUri} from "../../utils/utils";


function ProfileBox({className, heading, value,endIcon, ...props}) {

    return (
        <div className={`${className} flex px-4 py-3 dark:border-darkBgSecondary  last:border-0  justify-center items-center  flex-row gap-2`}>
            <p className={"font-semibold flex-1 text-base"}>
                {heading}
            </p>
            <p className={"font-normal text-sm text-gray-500"}>
                {value}
            </p>
            {
                endIcon && endIcon()

            }
        </div>

    )
}



export default function ViewProfile({className, ...props}) {
    const {session} = useSession();
    const profile = session?.profile;



    return (
        <div className={`flex  flex-col gap-12 ${className}`}>
            <div className={"flex flex-row grid- justify-center items-center gap-3"}>
                <DpCircle src={getUri(session?.profile?.dp_uri?.file)}/>
                <div className={"flex flex-1 flex-col justify-start items-start gap-1"}>
                    <p className={'font-bold text-sm lg:text-xl'}>{profile?.full_name}</p>
                    <p className={'font-normal text-xs lg:text-sm text-gray-400 flex flex-row justify-center items-center'}>

                    </p>
                </div>
                <div className={"flex-col flex justify-center items-center  p-4"}>
                    <Link
                        to={"/app/settings/edit"}
                        className={" text-xs  lg:text-base rounded-full px-3 py-1 justify-center cursor-pointer gap-2 items-center flex  bg-tertiary-150"}>
                        <p>
                            Edit Profile
                        </p>
                        <TfiMarkerAlt className={"text-secondary-900"}/>
                    </Link>

                </div>


            </div>
            <div className={"grid grid-cols-1  lg:grid-cols-2 rounded-xl border-[1px] dark:border-darkBgSecondary bg-gray-100 dark:bg-darkBgTertiary "}>

                <ProfileBox heading={"Roles"} className={" border-r-[1px]"} value={
                    session?.profile?.roles?.toString().replace(/,/g, ", ")
                }/>
                <ProfileBox heading={"Email"} className={" "} value={session?.profile?.email}/>
                {/*<ProfileBox heading={"Linked"} value={<GoogleBox/>} className={"border-b-[1px] lg:border-b-[0px] border-r-[1px]"}/>*/}
                {/*<ProfileBox heading={"Refer Code"} value={session?.profile?.referral_code}*/}
                {/*endIcon={*/}
                {/*()=><div className={"flex flex-row justify-center items-center"}>*/}

                {/*    <CiMedicalClipboard onClick={copyReffralText} className={"text-secondary-900 cursor-pointer"}/>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*/>*/}




            </div>
        </div>

    )
}