import React, {useEffect, useMemo} from "react";
import DocumentManagerTable from "../../components/base/document-manager-table/document-manager-table";

import {useSession} from "../../redux/Reducers/AuthReducer";
import URLS from "../../API/Axios/URLS";
import CreateDirectoryDropDown from "../../components/base/create-directory-drop-down/create-directory-drop-down";
import useListApi from "../../Hooks/useListApi";
import {IoEye} from "react-icons/io5";
import {IoMdEyeOff} from "react-icons/io";
import {useParams} from "react-router-dom";


export default function DocumentManager({className, ...props}) {
    const {session, authActions} = useSession()
    const {email} = useParams();

    let owner = email || session.profile?.email;
    const [data, filter, isLoading, actions] = useListApi(URLS.ReportFileList, {
        is_hidden: false,
        search: "",
        user_email: owner,
    })




    console.log(owner)

    useEffect(() => {
        actions.updateFilter({user_email: owner})
    }, [owner,email])
    return (
        <div className={` ${className}`}>
            <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Documents</h1>

            <div className={"flex justify-center items-center flex-row gap-4"}>
                {/*<Breadcrumb path={createPath()}*/}
                {/*            onPathClick={(index) => {*/}
                {/*                if (index === 0) {*/}
                {/*                    setStack([])*/}
                {/*                } else {*/}
                {/*                    setStack(stack.slice(0, index))*/}
                {/*                }*/}
                {/*            }}*/}
                {/*/>*/}

                <div className={"flex-1"}/>
                <div className={"flex flex-row-reverse gap-2 "}>
                    <CreateDirectoryDropDown
                        owner={owner}
                        refresh={() => {
                            actions.refresh()
                        }}
                    />

                    {authActions.is_admin() || session.profile?.email === owner ? <div title={"show hidden files"}
                                                    onClick={() => {
                                                        actions.updateFilter({is_hidden: !filter?.is_hidden})
                                                    }}
                                                    className={"p-2 text-2xl dark:text-white rounded-full cursor-pointer shadow-xl dark:bg-gray-700"}>
                        {
                            filter?.is_hidden ? <IoMdEyeOff/> : <IoEye/>
                        }

                    </div> : ""
                    }
                </div>

            </div>
            <div className={"flex flex-row  gap-5"}>
                <DocumentManagerTable
                    onDirDoubleClick={(dir) => {


                    }}
                    refresh={() => {
                        actions.refresh()
                    }}
                    data={data}/>
            </div>
        </div>

    )
}