export default function IconCard({className, color, title, iconColorClassName, value, onClick, icon, ...props}) {

    return (
        <div onClick={onClick}
             className={`${className} border-[1px] dark:border-gray-700 cursor-pointer min-w-0 rounded-lg shadow-sm overflow-hidden bg-white dark:bg-gray-800 `}>
            <div className="p-4 flex items-center">
                <div
                    style={{
                        backgroundColor: color + "1a",
                        color: color

                    }}
                    className={`p-3 rounded-full  mr-4 ${iconColorClassName ? iconColorClassName : "text-orange-500 dark:text-orange-100 bg-orange-100 dark:bg-orange-500"} `}>
                    {
                        icon
                    }
                </div>
                <div>
                    <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
                    <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">{value}</p></div>
            </div>
        </div>

    )
}