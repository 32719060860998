import BaseModal from "../base-modal/base-modal";


export default function ConfirmationModal({isOpen,setOpen, title, message, onConfirm, onCancel}) {
  return (
    <BaseModal
        innerClassName={"md:w-[700px] md:max-w-[50%]"}

        isBoxOpen={isOpen} title={title}
               setIsBoxOpen={setOpen}>
        <div className="flex flex-col gap-y-4">
            <p className={"text-center font-semibold text-primarySelected dark:text-primarySelectedDark "}>{message}</p>
            <div className="flex  justify-end gap-x-2">
                <button onClick={onCancel} className="px-4 py-2 bg-red-600 text-white rounded-md">Cancel</button>
                <button onClick={()=>{
                    setOpen(false)
                    onConfirm()
                }} className="px-4 py-2 bg-green-600 text-white rounded-md">Confirm</button>
            </div>
        </div>
    </BaseModal>
  );
}
