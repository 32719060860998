import {Route, Routes} from "react-router-dom";
import AddUpdateDoctor from "./add-update-doctor/add-update-doctor";
import DoctorList from "./doctor-list/doctor-list";
import DoctorProfile from "./doctor-profile/doctor-profile";

export default function DoctorEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<DoctorList className={className} {...props} />}/>
            <Route path="/:id" element={<DoctorProfile className={className} {...props} />}/>

        </Routes>

    )
}
