import PageTitle from "../../../components/utils/PageTitle";
import React from "react";
import PaymentTable from "./payment-table";
import Payments from "../components/Payments";

export default function PaymentList({className, ...props}) {
    const [refresh, setRefresh] = React.useState(false);
    return (
        <div className={` ${className}`}>

            <Payments refresh={refresh} setRefresh={setRefresh} />
            <PageTitle>
                Payment List
            </PageTitle>


            <PaymentTable refresh={refresh} setRefresh={setRefresh} />
        </div>

    )
}
