import {
    LocalUser, RemoteUser,
    useIsConnected,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useConnectionState,
    useRemoteUsers
} from "agora-rtc-react";
import {useEffect, useState} from "react";
import config from "./config";
import {BiSolidCamera, BiSolidCameraOff} from "react-icons/bi";
import {MdMic, MdMicOff} from "react-icons/md";
import {FaPhone} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../../Hooks/useForm";
import {toast} from "react-toastify";
import {useSession} from "../../../redux/Reducers/AuthReducer";

export const Call = ({
    callType="VIDEO CALL",

                     }) => {
    const [calling, setCalling] = useState(false);
    const isConnected = useIsConnected(); // Store the user's connection status

    const [appId, setAppId] = useState(config.appId);

    const {id} = useParams();
    const [form, error, loading, setFormData, actions] = useForm({appointment:id})
    const [call, setCall] = useState();

    useEffect(() => {
        actions.get('appointments/get/'+id).then((data) => {
            if (data.status === 200) {
                setCall(data.data)
                if(!data.data.media?.includes("VIDEO")){
                    setCamera(false)
                }
            }

        }).catch((error) => {
            toast.error("Error in fetching call details")
        })


    }, [id]);
    useEffect(() => {
        if(call){
            setCalling(true)
        }
    }, [call]);
    const {session} = useSession()
    const profileId = session.profile.id;
    useJoin({appid: appId, channel: call?.call?.channel,uid:session?.profile?.id, token:call?.call?.patient_token ? call?.call?.patient_token : null}, calling);
    const connectionState = useConnectionState();
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    const { localCameraTrack } = useLocalCameraTrack(cameraOn);
    usePublish([localMicrophoneTrack, localCameraTrack]);
    const remoteUsers = useRemoteUsers();
    const navigate= useNavigate()
    useEffect(() => {
        if(call){
        //     set an interval to check the end time of the call
            const interval = setInterval(() => {
                if(call?.call?.endTime){
                    const endTime = new Date(call?.call?.endTime)
                    const currentTime = new Date()
                    // if 6 less than 6 minutes are left in the call
                    if(currentTime>endTime){
                        setCalling(false)
                        clearInterval(interval)
                        navigate("/app/appointments")

                    }
                    else if(currentTime.getTime() > endTime.getTime() - 60*1000){
                        toast.warning("Call will end in 1 minute")
                    }
                    else if(currentTime.getTime() > endTime.getTime() - 3*60*1000){
                        toast.warning("Overtime in the call")
                    }
                    else if(currentTime.getTime() > endTime.getTime() - 4*60*1000){
                        toast.warning("Less than 1 minute left in the call")
                    }
                    else if(currentTime.getTime() > endTime.getTime() - 6*60*1000){
                        toast.warning("Less than 3 minutes left in the call")
                    }

                }
            }, 1000);
            return () => clearInterval(interval)
        }
    }, []);

    // check if error occurs in joining the channel
    if ((connectionState === "DISCONNECTED" || connectionState === "FAILED") && call) {
        return (
            <div>
                <p>{
                    calling ? "Call Time Expired. " : "Calling..."
                }</p>
            </div>
        );
    }

    return (
        <>
            <div className="room">
                {
                    isConnected
                }
                {isConnected ? (
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            gap: 10,
                            flexDirection: "row",
                        }}
                        className="user-list">

                        <div style={{
                            width: 300,
                            display: "flex",
                            backgroundColor: "black",
                            height:300,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            borderRadius:"50%",
                            overflow:"hidden",
                            borderColor:"orange",
                            borderWidth:1,

                        }} className="user">
                            <LocalUser
                                cameraOn={cameraOn}
                                micOn={micOn}
                                muted={micOn}
                                muteAudio={!micOn}
                                videoTrack={localCameraTrack}
                                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                            >
                                <samp
                                    style={{
                                        color:"white",
                                        width:300,
                                        flex:1,
                                        position:"absolute",
                                        right:0,
                                        backgroundColor:"rgba(0,0,0,0.5)",
                                    }}
                                    >You</samp>
                            </LocalUser>
                        </div>
                        {remoteUsers.map((user) => (
                            <div style={{
                                width: 300,
                                display: "flex",
                                backgroundColor: "black",
                                height:300,
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                borderRadius:"50%",
                                overflow:"hidden",
                                borderColor:"orange",
                                borderWidth:1,

                            }} className="user">
                                <RemoteUser
                                    cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                                    user={user}
                                    playAudio={true}
                                >
                                    <samp
                                        style={{
                                            color: "white",
                                            width: 300,
                                            flex: 1,
                                            position: "absolute",
                                            right: 0,
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                        }}
                                    >{call?.doctor?.user?.full_name}</samp>
                                </RemoteUser>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            {isConnected ? (
                <div className={"flex flex-row gap-4 mt-6"}>
                    {
                        call?.media?.includes("VIDEO") &&
                        <button
                            onClick={() => setCamera(!cameraOn)}
                            className={"bordered-primary-button w-12 h-12 text-2xl flex-col-center "}>
                            {
                                cameraOn ? <BiSolidCamera className={"w-6 h-6"}/> :
                                    <BiSolidCameraOff className={"w-6 h-6"}/>
                            }

                        </button>}
                    <button
                        onClick={() => setMic(!micOn)}
                        className={"bordered-primary-button  w-12 h-12 text-2xl flex-col-center "}>
                        {
                            micOn ? <MdMic className={"w-6 h-6"}/> : <MdMicOff className={"w-6 h-6"}/>
                        }
                    </button>
                    <button
                        onClick={() => setCalling(false)}
                        className={"rounded-full bg-red-700   w-12 h-12 text-2xl flex-col-center "}>
                        <FaPhone className={"w-4 h-4 text-white"}/>

                    </button>
                </div>
            ):
                (<div className={"flex flex-col gap-4 mt-6"}>

                    <button
                        onClick={() => {
                            // setCalling(true)
                        //     move to previous page
                            navigate("/app/appointments")


                        }}
                        className={"rounded-full bg-green-700   w-12 h-12 text-2xl flex-col-center "}>
                        <FaPhone className={"w-4 h-4 text-white"}/>
                        <p>
                            Joining
                        </p>


                    </button>
                </div>)
            }
        </>
    );
};

export default Call;
