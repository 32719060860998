

const SelectedClassName = "align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-white bg-purple-900 border border-transparent hover:bg-purple-700 focus:shadow-outline-purple"
const NormaClassName = "align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600 dark:text-gray-400  border border-transparent active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10"

export default function Paginator({className, currentPage, totalPages, onNext, onPrev, onPageNumberClick, ...props}) {
    const  TotalPages = 5;
    function  getNextFivePages(){
        let pages = []
        let nextFivePages = currentPage + TotalPages;
        let myPointer = currentPage;
        if(nextFivePages > totalPages){
            myPointer = currentPage ;
            nextFivePages = currentPage + (totalPages - currentPage)
        }
        for (let i = myPointer; i <= nextFivePages; i++) {
            pages.push(i)
        }
        return pages
    }

    return (
        <div className={`flex flex-row ${className}`}>
            <ul className={"inline-flex items-center"}>
                <li>
                    <button
                        onClick={() => {
                            if (currentPage - 1 > 0 && onPrev)
                                onPrev(currentPage - 1)
                        }}
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none p-2 rounded-md text-gray-600 dark:text-gray-400  border border-transparent active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10"
                        type="button" aria-label="Previous">
                        <svg className="h-3 w-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd" fillRule="evenodd"></path>
                        </svg>
                    </button>
                </li>

                {
                    Array.from(getNextFivePages()).map((i) => {
                        return (

                            <li key={i}>
                                <button
                                    onClick={() => {
                                        if (onPageNumberClick)
                                            onPageNumberClick(i )
                                    }}
                                    className={currentPage === i  ? SelectedClassName : NormaClassName}
                                    type="button">{i}
                                </button>
                            </li>
                        )
                    })
                }


                <li>
                    <button
                        onClick={() => {
                            if (currentPage + 1 <= totalPages && onNext)
                                onNext(currentPage + 1)
                        }}
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none p-2 rounded-md text-gray-600 dark:text-gray-400  border border-transparent active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10"
                        type="button" aria-label="Next">
                        <svg className="h-3 w-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                                fillRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
            </ul>

        </div>

    )
}