import {useLocation} from "react-router-dom";
import React from "react";


function Box({title, value, noValueMessage}) {


    return (
        <div className={"flex flex-col gap-2"}>
            <p className={"text-lg font-semibold"}>{title}</p>
            <p className={"opacity-70"}>{value ? value : noValueMessage}</p>
        </div>
    )
}

export default function Notes({className, ...props}) {
    const location = useLocation();
    const appointment = location?.state;

     return (
        <div>
            <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Appointment Details</h1>
            <div className={"flex flex-col  gap-5"}>
                <div className={"shadow-md bg-white dark:bg-darkBgSecondary py-4 rounded-md px-3"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-7"}>
                        <Box
                            title={"Chief Complaints"}
                            value={appointment?.chiefComplaints}
                            noValueMessage={"No Chief Complaints"}
                        />
                        <Box
                            title={"Investigations / Diagnosis"}
                            value={appointment?.investigations}
                            noValueMessage={"No Investigations / Diagnosis"}
                        />
                        <Box
                            title={"Medications"}
                            value={appointment?.medications}
                            noValueMessage={"No Medications"}
                        />
                        <Box
                            title={"Treatment & Preventions"}
                            value={appointment?.treatment}
                            noValueMessage={"No Treatment & Preventions"}
                        />
                        <Box
                            title={"Any Referral"}
                            value={appointment?.referral}
                            noValueMessage={"No Referral"}
                        />
                        <Box
                            title={"Diseases"}
                            value={appointment?.diseases}
                            noValueMessage={"No Referral"}
                        />
                    </div>

                </div>
            </div>
        </div>

    )
}