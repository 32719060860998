


 export default function Table({className,...props}) {
 
     return (
         <table className={`w-full overflow-x-auto whitespace-no-wrap ${className}`}>
             {
                    props.children
             }

         </table>
         
     )
 }