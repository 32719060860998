import CallDetails from "./call-details";
import CallConsultation from "./call-consultation";


export default function OngoingCall({className, image, name, ...props}) {


    return (
        <div className={` ${className}  flex flex-col   gap-8`}>
            <div className={"flex flex-col"}>
                <CallDetails/>
            </div>
            <CallConsultation/>

        </div>


    )
}
