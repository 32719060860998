import PageTitle from "../../../components/utils/PageTitle";
import WeekScheduleTable from "./WeekScheduleTable";
import {useLocation} from "react-router-dom";


export default function Schedule({className, ...props}) {
    const {state} = useLocation();
    const {doctor} = state
    return (
        <div className={` ${className}`}>
            <PageTitle>
                Weekly Schedule of {doctor.user?.full_name}
            </PageTitle>
            <WeekScheduleTable/>

        </div>
    )
}
