import {BASE_URL} from "../../settings/settings";


const URLS = {
    BASE_URL: BASE_URL,
    login: 'auth/api-token-auth/',
    ResetPassword: 'auth/reset-password',
    ValidateOtp: 'auth/validate-otp',
    LoginOtpAuthnticate: 'auth/authenticate-login-otp',
    SetNewPassword: 'auth/set-new-password',
    UploadFile: 'auth/upload-file',
    Dashboard: 'auth/dashboard',
    ChangePassword: 'auth/change-password',
    updateProfile: 'auth/update-profile',
    SendEmail: 'auth/send-email',


    // Document Manager
    getRootDocument: "document/get-my-root-directory",
    document: "document/crud",


    //Report File
    ReportFileList: "document/report-file-list",
    ReportFileCrud: "document/report-file",


    //appointments
    getAvailableSlots: "appointments/getAvailableSlots",
    saveAppointment: "appointments/saveAppointment",
    appointmentList: "appointments/list",
    saveSchedule: "appointments/saveSchedule",
    saveDoctorSchedule: "appointments/saveDoctorSchedule", // this is the same as saveSchedule but adminn save all doctors schedule
    getSchedule: "appointments/getSchedule",
    getDoctorSchedule: "appointments/getDoctorSchedule",  // this is the same as getSchedule but admin can view all doctors schedule
    verifyPayment: "appointments/verifyPayment",
    initiateCallRequest: 'appointments/initiateCallRequest',
    joinCall: 'appointments/joinCall',
    patientAppointments: 'appointments/patient-appointments',
    updateNotes: 'appointments/updateNotes',


    //patient
    PatientCrud: "patients/profile",
    PatientRegister: "patients/register-patient",
    PatientList: "patients/patient-list-view",

    //devices
    DeviceCrud: "devices/crud",
    DeviceList: "devices/device-list-view",
    AssignedDevices:"devices/all-assigned-devices",
    deviceActions:"devices/device-actions",
    getTelemetryData:"devices/get-telemetry-data",
    getAverageData:"devices/get-average-data",


    //doctor
    DoctorsCrud: "doctors/profile",
    DoctorsList: "doctors/doctor-list-view",
    DoctorDashboard: "doctors/dashboard",
    Diseases: "doctors/get-or-create-dieases",
    DoctorReport: "doctors/report-csv",


    //Payments
    PaymentList: "appointments/listAllPayments",
    PaymentCSV: "appointments/generatePaymentCSV",
    PaymentAdd: "appointments/createPaymentAdmin",
}


export default URLS;
