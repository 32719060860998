import {Route, Routes} from "react-router-dom";
import Calls from "./calls";

export default function CallEntry({className, ...props}) {

    return (<Routes>

            <Route path="/:id" element={<Calls className={className} {...props} />}/>
            <Route path="/" element={<Calls className={className} {...props} />}/>
        </Routes>

    )
}