import {Route, Routes} from "react-router-dom";
import Schedule from "./Schedule/Schedule";

export default function ScheduleAdminEntry({className, ...props}) {

    return (<Routes>
            <Route path="/:id" element={<Schedule className={className} {...props} />}/>
        </Routes>

    )
}
