import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";


export default function AdminLayout({className,...props}) {

     return (
         <div

             className={`w-[100vw] bg-[#f3f4f6] h-[100vh] flex flex-row  dark:bg-darkTheme-900  duration-300 ease-linear  ${className}`}>
             <Sidebar />
             <div className={"flex flex-col flex-1"}>
                 <Navbar />
                 <main className="h-full overflow-y-auto">
                     <div className="container grid px-6    mx-auto">{props.children}</div>
                 </main>


             </div>


         </div>

     )
 }
