// import loginOffice from "../../assets/img/login-office.jpeg";
// import loginOfficeDark from "../../assets/img/login-office-dark.jpeg";
import hospital from "../../assets/img/hospital.webp";
import background from "../../assets/img/background.jpg";
import nightHospital from "../../assets/img/night-hospital.webp";
import {useTheme} from "../../redux/Reducers/ThemeReducer";

export default function AuthLayout({className, ...props}) {
    const {isDarkMode} = useTheme();
    return (
        <div className={"w-full dark:bg-gray-800 h-full flex"}>
            <div className={"flex-[7] hidden lg:flex bg-cover  bg-center"}
                 style={{backgroundImage: `url(${isDarkMode ? background : background})`}}>
            >
            </div>
            <div className={"flex-[8] flex p-8 overflow-auto justify-center pt-[7rem]"}>
                {
                    props.children

                }
            </div>


        </div>


    )
}