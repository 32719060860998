import {Route, Routes} from "react-router-dom";
import Payments from "./components/Payments";
import PaymentList from "./payment-list/payment-list";


export default function PaymentsEntry({className, ...props}) {

    return (
        <Routes>
            <Route path="/" element={<PaymentList className={className} {...props} />}/>
            <Route path="/add" element={<Payments className={className} {...props} />}/>
        </Routes>

    )
}
