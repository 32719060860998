import React from "react";
import PageTitle from "../../../components/utils/PageTitle";
import DoctorsModal from "../../../Modals/doctor-slection-modal/doctor-selction-modal";
import DoctorTable from "../../doctor/doctor-list/component/doctor-table";
import AppointmentTable from "./AppointmentTable";


export default function AppointmentList({className, ...props}) {

    return (
        <div className={` ${className}`}>

            <PageTitle>
                Appointment List
            </PageTitle>
            <AppointmentTable/>
        </div>

    )
}
