import TableContainer from "../../Table/TableContainer";
import TableHeader from "../../Table/TableHeader";
import Table from "../../Table/Table";
import TableCell from "../../Table/TableCell";
import TableBody from "../../Table/TableBody";
import TableRow from "../../Table/TableRow";
import {AiFillFolder} from "react-icons/ai";
import {defaultStyles, FileIcon} from "react-file-icon";
import {getUri} from "../../../utils/utils";
import {IoEye} from "react-icons/io5";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {FcDeleteRow} from "react-icons/fc";
import {MdDelete} from "react-icons/md";
import {useSession} from "../../../redux/Reducers/AuthReducer";


function DirectoryRow({dir, ...props}) {

    return (
        <TableRow
            onDoubleClick={() => {
                if (props.onDoubleClick)
                    props.onDoubleClick(dir)
            }}
            className={"hover:opacity-70 cursor-pointer"}>
            <TableCell>
                <div className="flex items-center text-sm gap-1">
                    <AiFillFolder className={"primary-text text-xl "}/>
                    <div>
                        <p className="font-semibold">{dir?.name}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <span className="text-sm">{dir?.size || '-'}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{dir?.created_at?.date}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{dir?.updated_at?.date}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{dir?.owner?.email}</span>
            </TableCell>
        </TableRow>

    )
}


function FileRow({file, ...props}) {
    const {session, authActions} = useSession()

    function getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2) || "txt";
    }

    function deleteFile() {
        // ask for conformation
        let response = window.confirm("Are you sure you want to delete this file")
        if (!response) return;

        axiosInstance.delete(URLS.ReportFileCrud, {
            data: {
                id: file?.id
            }
        }).then((res) => {
            props?.refresh?.()
            toast.success("File deleted successfully")
        }).catch((err) => {
            toast.error("something went wrong")
        })
    }

    return (
        <TableRow

        >
            <TableCell>
                <div className="flex items-center text-sm gap-2">
                    <div className={"w-5 h-5"}>
                        <FileIcon extension={getFileExtension(file?.name)} {
                            ...defaultStyles[getFileExtension(file?.name)]
                        }
                        />
                    </div>
                    <div>
                        <p className="font-semibold">{file?.name}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <span className="text-sm">{file?.size}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{file?.uploaded_at?.date}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{file?.uploaded_at?.date}</span>
            </TableCell>
            <TableCell>
                <span className="text-sm">{file?.owner || "-"}</span>
            </TableCell>
            <TableCell>
                <div className={"flex flex-row gap-2"}>
                    <IoEye
                        className={"cursor-pointer"}
                        onClick={() => {
                            let url = getUri(file?.file);
                            window.open(url, '_blank');

                        }}
                    />

                    {authActions.is_admin() && <MdDelete className={"cursor-pointer"} onClick={deleteFile}/>}
                </div>
            </TableCell>
        </TableRow>

    )
}


export default function DocumentManagerTable({className, data, onDirDoubleClick, ...props}) {
    const files = data?.results;


    return (
        <div className={`mt-4 w-full overflow-auto ${className}`}>
            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Document</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Modified at</TableCell>
                            <TableCell>owner</TableCell>
                            <TableCell>Actions</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>


                        {files?.map((file, i) => (
                            <FileRow file={file}
                                     refresh={() => {
                                         props?.refresh?.()
                                     }}
                                     key={i}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}