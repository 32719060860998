import {Route, Routes} from "react-router-dom";
import Schedule from "./Schedule/Schedule";

export default function ScheduleEntry({className, ...props}) {

    return (<Routes>
            <Route path="/" element={<Schedule className={className} {...props} />}/>
        </Routes>

    )
}
