import BaseModal from "../base-modal/base-modal";
import useForm from "../../Hooks/useForm";
import FormItem from "../../components/Form/FormItem/FormItem";
import FormButton from "../../components/Form/Button/FormButton";
import URLS from "../../API/Axios/URLS";
import {toast} from "react-toastify";
import {useEffect} from "react";


export default function AddDiseaseModal({className, isOpen, setIsOpen, symptoms,onComplete, remarks, ...props}) {
    const [form, error, loading, setFormData, actions] = useForm({
        name: "",
    })

    function submitForm() {
        actions.post(URLS.Diseases).then(res => {
            if (onComplete) {
                onComplete()
            }
            toast.success(res.data.message || "Disease added successfully",{
                toastId: "add-disease"
            })
            actions.clearError()
            setIsOpen(false)


        }).catch(err => {
            toast.error(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong",{
                toastId: "add-disease"
            })
        })
    }

    useEffect(() => {
        setFormData({
            name: ""

        })
    }, [isOpen]);

    return (
        <BaseModal innerClassName={"dark:bg-black  lg:w-1/3"} isBoxOpen={isOpen} title={"Add Disease"}
                   autoClose={true}
                   setIsBoxOpen={setIsOpen}>


            <div className={"grid grid-cols-1  gap-3 pt-3"}>

                <FormItem
                    label={"Disease Name"}
                    variant={"auth"}
                    message={error?.name}
                >
                    <input
                        type="text"
                        className={"w-full outline-none rounded-xl p-2  lg:py-3 bg-transparent text-sm "}
                        placeholder={"Disease Name"}
                        value={form.name}
                        onChange={(e) => setFormData({
                            ...form,
                            name: e.target.value
                        })}
                    />
                </FormItem>

                <FormButton
                    onClick={submitForm}
                    className={"bg-purple-500 text-white"}
                     loading={loading}
                >
                    Add Disease
                </FormButton>
            </div>
        </BaseModal>

    )
}