import {useDrawer} from "../../redux/Reducers/DrawerReducer";
import {RxHamburgerMenu} from "react-icons/rx";

export default function NavigationDrawerButton({className, ...props}) {
    const {actions} = useDrawer();


    return (
        <div>
            <RxHamburgerMenu onClick={actions.toggleDrawer} className={`${className} text-brand-500 lg:hidden text-xl `}/>
        </div>
    )
}