import React from "react";
import PatientTable from "./component/patient-table";
import PageTitle from "../../../components/utils/PageTitle";


export default function PatientList({className, ...props}) {

    return (
        <div className={` ${className}`}>
            <PageTitle>
                Patient List
            </PageTitle>
            <PatientTable/>
        </div>

    )
}