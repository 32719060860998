import FormItem from "../../../components/Form/FormItem/FormItem";
import React, {useEffect} from "react";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import Select from 'react-select'
import "./index.css"
import useListApi from "../../../Hooks/useListApi";
import AddDiseaseModal from "../../../Modals/add-disease-modal/add-disease-modal";

export default function CallConsultation({className, ...props}) {
    const [form, error, loading, setFormData, actions] = useForm()
    const [data, filter, isLoading, Listactions] = useListApi(URLS.Diseases)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const {id} = useParams();
    useEffect(() => {

    }, [id]);
    useEffect(() => {
        if (id) {
            actions.get('appointments/get/' + id).then((data) => {
                if (data.status === 200) {
                    console.log('=====', data.data)
                    setFormData({
                        ...form,
                        symptoms: data.data?.symptoms,
                        remarks: data.data?.remarks,
                        id: id
                    })
                }

            }).catch((error) => {
                toast.error("Error in fetching call details", {
                    toastId: "fetching-call-details"
                })
            })
        }
    }, [id]);

    function submitForm() {
        actions.post(URLS.updateNotes).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message || "Consultation notes saved successfully")


            }
        }).catch(err => {
            console.log(err)
            toast.error(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
        })
    }


    return (
        <div className={` ${className} card p-4 `}>
            <AddDiseaseModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onComplete={() => Listactions.load()}
            />
            <div className={"flex flex-col gap-4"}>
                <p className={"font-semibold text-xl"}>
                    Consultation
                </p>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>

                    <FormItem
                        label={"Chief Complaints"}
                        type={"error"}
                        className={"mb-2"}
                        variant={"auth"}
                    >
                        <textarea
                            className={"h-20"}
                            value={form?.chiefComplaints}
                            onChange={(e) => setFormData({...form, chiefComplaints: e.target.value})}
                            placeholder={"Chief Complaints"}/>
                    </FormItem>

                    <FormItem
                        label={"Investigations / Diagnosis"}
                        type={"error"}
                        className={"mb-2"}
                        variant={"auth"}
                    >
                        <textarea
                            className={"h-20"}
                            value={form?.investigations}
                            onChange={(e) => setFormData({...form, investigations: e.target.value})}
                            placeholder={"investigations / Diagnosis"}/>
                    </FormItem>
                    <FormItem
                        label={"Medications"}
                        type={"error"}
                        className={"mb-2"}
                        variant={"auth"}
                    >
                        <textarea
                            className={"h-20"}
                            value={form?.medications}
                            onChange={(e) => setFormData({...form, medications: e.target.value})}
                            placeholder={"Medications"}/>
                    </FormItem>

                    <FormItem
                        label={"Treatment & Preventions"}
                        type={"error"}
                        className={"mb-2"}
                        variant={"auth"}
                    >
                        <textarea
                            className={"h-20"}
                            value={form?.treatment}
                            onChange={(e) => setFormData({...form, treatment: e.target.value})}
                            placeholder={"Medications"}/>
                    </FormItem>
                    <FormItem
                        label={"Any Referral"}
                        type={"error"}
                        className={"mb-2"}
                        variant={"auth"}
                    >
                        <input
                            value={form?.referral}
                            placeholder={"Referral"}
                            onChange={(e) => setFormData({...form, referral: e.target.value})}
                        />


                    </FormItem>

                    <div className={"flex flex-col selecte-dea gap-2"}>
                        <label

                            className={` text-sm ml-1.5 font-medium text-gray-500 dark:text-white mb-2`}
                        >
                            Diseases
                        </label>
                        <div className={"inline-flex gap-2"}>
                            <Select
                                isMulti={true}
                                onChange={(e) => {
                                   let diseases = e.map((item) => item.label)
                                    diseases = diseases.join(',')
                                     setFormData({...form, diseases: diseases})
                                }}
                                className={"rounded-md flex-1"}
                                options={data?.data}/>

                            <div
                                onClick={() => setIsModalOpen(true)}
                                className={"font-bold text-white cursor-pointer hover:bg-purple-800 px-4 justify-center items-center inline-flex text-xl rounded-md bg-purple-900"}>
                                +
                            </div>
                        </div>

                    </div>


                </div>
                <FormButton
                    onClick={submitForm}
                    className={"w-1/2"}
                >
                    Save
                </FormButton>
            </div>
        </div>

    )
}
