import {BiCalendarCheck, BiDevices, BiHomeAlt} from 'react-icons/bi'
import {HiDocument, HiOutlineClipboardList} from 'react-icons/hi'
import {BsCardText, BsCash, BsPerson} from 'react-icons/bs'
import {AiFillCalendar, AiOutlineCalendar, AiOutlineContacts} from 'react-icons/ai'

import {FiSettings} from "react-icons/fi";
import {MdOutlineMenuBook, MdSwapCalls} from "react-icons/md";
import {FcDocument} from "react-icons/fc";
import {CgFileDocument} from "react-icons/cg";
import {GiCash, GiDoctorFace} from "react-icons/gi";
import {FaUserDoctor} from "react-icons/fa6";
import Dashboard from "../../DemoScreens/dashboard/Dashboard";
import DashboardDoctor from "../../DemoScreens/dashboardDoctor/Dashboard";
import DocumentManager from "../../DemoScreens/document-manager/document-manager";
import PaymentsEntry from "../../DemoScreens/Payments/PaymentsEntry";
import PatientEntry from "../../DemoScreens/patient/patient";
import DoctorEntry from "../../DemoScreens/doctor/doctor";
import CallEntry from "../../DemoScreens/calls/CallEntry";
import AppointmentEntry from "../../DemoScreens/Appointments/AppointmentEntry";
import AppointmentAdminEntry from "../../DemoScreens/AppointmentsAdmin/AppointmentEntry";
import ScheduleEntry from "../../DemoScreens/Scheduling/ScheduleEntry";
import SettingIndex from "../../DemoScreens/Setting";
import DevicesEntry from "../../DemoScreens/devices";
import { ImProfile } from "react-icons/im";


const routes = [
    {
        path: '/app/dashboard', // the url
        icon: BiHomeAlt, // the component being exported from icons/index.js
        role: ['admin'],
        name: 'Dashboard', // name that appear in Sidebar
        component: Dashboard

    },
    {
        path: '/app/dashboard-doctor', // the url
        icon: BiHomeAlt, // the component being exported from icons/index.js
        role: ['doctor'],
        name: 'Dashboard', // name that appear in Sidebar
        component: DashboardDoctor
    },
    {
        path: '/app/payments', // the url
        icon: GiCash, // the component being exported from icons/index.js
        role: ['admin'],
        name: 'Payments', // name that appear in Sidebar
        component: PaymentsEntry
    },
    {
        path: '/app/document-manager', // the url
        icon: CgFileDocument, // the component being exported from icons/index.js
        role: ['admin', 'patient'],
        name: 'Documents', // name that appear in Sidebar
        component: DocumentManager
    },
    {
        path: '/app/patient', // the url
        icon: BsPerson, // the component being exported from icons/index.js
        role: ['admin', 'doctor'],
        name: 'Patients', // name that appear in Sidebar,
        component: PatientEntry
    },
    {
        path: "/app/devices",
        icon: BiDevices,
        role: ['admin'],
        name: "Devices",
        component: DevicesEntry
    },
    {
        path: '/app/doctor', // the url
        icon: FaUserDoctor, // the component being exported from icons/index.js
        role: ['admin'],
        name: 'Doctors', // name that appear in Sidebar
        component: DoctorEntry
    }, {
        path: '/app/calls', // the url
        icon: MdSwapCalls, // the component being exported from icons/index.js
        role: ['doctor'],
        name: 'Calls', // name that appear in Sidebar
        component: CallEntry
    },
    {
        path: '/app/appointments', // the url
        icon: AiOutlineCalendar, // the component being exported from icons/index.js
        role: ['patient'],
        name: 'Appointments', // name that appear in Sidebar
        component: AppointmentEntry
    }, {
        path: '/app/appointments-admin', // the url
        icon: AiOutlineCalendar, // the component being exported from icons/index.js
        role: ['admin'],
        name: 'Appointments', // name that appear in Sidebar
        component: AppointmentAdminEntry
    },
    {
        path: "/app/schedule",
        icon: BiCalendarCheck,
        role: ['doctor'],
        name: "Schedule",
        component: ScheduleEntry
    },

    {
        path: '/app/settings',
        icon: ImProfile,
        name: 'Profile',
        role: ['doctor', 'admin', 'patient'],
        component: SettingIndex
    },


]

export default routes
