import {useParams} from "react-router-dom";
import PageTitle from "../../../../components/utils/PageTitle";
import {useEffect, useState} from "react";
import DeviceModal from "../../../../Modals/device-slection-modal/device-selction-modal";
import {FcCancel} from "react-icons/fc";
import {BiX} from "react-icons/bi";
import ConfirmationModal from "../../../../Modals/confirmationModal/ConfirmationModal";
import useListApi from "../../../../Hooks/useListApi";
import URLS from "../../../../API/Axios/URLS";
import useForm from "../../../../Hooks/useForm";
import {toast} from "react-toastify";


function DeviceItem({device,id,deviceActions, confirmationModalData, setConfirmationModalData}) {
    const [form, error, loading, setFormData, actions] = useForm({
        patientId: id
    });

    return (
        <div className={"flex border rounded-xl p-2 my-2 shadow-inner items-center"}>
            <div className={"w-full grid grid-cols-1 lg:grid-cols-3 gap-2 items-center"}>
                <div className={"items-center col-span-1 flex flex-col"}>
                    <p className={"flex-1 text-lg capitalize"}>
                        {device?.deviceType || "Device Type"}
                    </p>
                    <p className={"flex-1 text-xs text-gray-500 dark:text-gray-400"}>
                        Device Type
                    </p>
                </div>
                <div className={"items-center flex flex-col"}>
                    <p className={"flex-1 text-lg"}>
                        {device?.deviceId ||"Device ID"}
                    </p>
                    <p className={"flex-1 text-xs text-gray-500 dark:text-gray-400"}>
                       Device ID
                    </p>
                </div>

                <div className={"items-center flex flex-col"}>
                    <p className={"flex-1 text-lg "}>
                        {device?.modelNo || "Device Model"}
                    </p>
                    <p className={"flex-1 text-xs text-gray-500 dark:text-gray-400"}>
                        Device Model
                    </p>
                </div>


            </div>
            <p className={"flex-1"}>


                <BiX
                    onClick={() => {
                        setConfirmationModalData({
                            ...confirmationModalData,
                            isOpen: true,
                            title: "Remove Device",
                            message: "Are you sure you want to un-assign this device to patient?",
                            onConfirm: () => {
                                actions.post(URLS.deviceActions,(prev)=>{
                                    return {
                                        ...prev,
                                        id: device?.id,
                                        patient: id,
                                        action: "un-assign"
                                    }
                                }).then(res=>{
                                    if(res) {
                                        toast("Device Un-assigned Successfully", {
                                            type: "success"
                                        })
                                        deviceActions.refresh();
                                    }


                                })
                            }

                        })
                    }}
                    size={30}
                    className={"text-red-500 cursor-pointer"}/>
            </p>
        </div>
    )
}

export default function AssignedDevices({className, ...props}) {
        const {id} = useParams();
        const [form, error, loading, setFormData, actions] = useForm({
            patientId: id
        });



        let [data, filter, isLoading, deviceActions] = useListApi(URLS.AssignedDevices, {
            patientId: id
        });
        const [isOpen, setIsOpen] = useState(false);
        const [confirmationModalData, setConfirmationModalData] = useState({
            isOpen: false,
            title: "Remove Device",
            message: "",
            onConfirm: () => {},
            setOpen: () => {
                setConfirmationModalData({
                    ...confirmationModalData,
                    isOpen: false
                })
            },
            onCancel: () => {
                setConfirmationModalData({
                    ...confirmationModalData,
                    isOpen: false
                })
            }
        });
        useEffect(() => {
            if (error?.message) {
                toast.error(error?.message)
            }

        }, [error]);

        return (
            <div className={` ${className} dark:text-white overflow-auto  card  p-4`}>
                <div className={"flex flex-col gap-4"}>

                    <div className={"flex flex-row gap-2 justify-center items-center"}>
                        <ConfirmationModal
                            {...confirmationModalData}
                            />

                        <DeviceModal
                            notAssignedOnly
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            title={"Select Device"}
                            onSelect={(device) => {
                                setIsOpen(false)
                                setConfirmationModalData({
                                    ...confirmationModalData,
                                    isOpen: true,
                                    title: "Assign Device",
                                    message: "Are you sure you want to assign this device to patient?",
                                    onConfirm: () => {
                                        actions.post(URLS.deviceActions,(prev)=>{
                                            return {
                                                ...prev,
                                                id: device?.id,
                                                patient: id,
                                                action: "assign"
                                            }
                                        }).then(res=>{
                                            if(res) {
                                                toast("Device Assigned Successfully", {
                                                    type: "success"
                                                })
                                                deviceActions.refresh();
                                            }


                                        })
                                    }

                                })
                            }}
                            description={"Select Device to assign to patient"}

                        />
                        <p className={"font-bold dark:opacity-60 flex-1 text-xl"}>
                            Assigned Devices
                        </p>

                        <p onClick={()=>{
                                setIsOpen(true);
                        }} className={"tex-sm text-white rounded-lg bg-brand-500 p-2 cursor-pointer"}>
                            Assign Device
                        </p>
                    </div>
                    <div className={"flex flex-col h-[395px] max-h-[395px] overflow-auto gap-4"}>
                        {
                            data?.results?.map((item, index) => {
                                return <DeviceItem  key={index} device={item?.device} id={id} deviceActions={deviceActions} confirmationModalData={confirmationModalData} setConfirmationModalData={setConfirmationModalData}/>
                            })
                        }

                    </div>
                </div>
            </div>

        )

}
