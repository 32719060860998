import {FaPhone} from "react-icons/fa";
import {MdMic} from "react-icons/md";
import {BiPause, BiSolidCamera, BiSolidCameraOff} from "react-icons/bi";
import AgoraRTC, {AgoraRTCProvider, useRTCClient} from "agora-rtc-react";
import {StrictMode, useState} from "react";
import config from "./config";
import Call from "./Call";


export default function CallDetails({className,...props}) {
    const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: config.selectedProduct }));
    agoraEngine.enableAudioVolumeIndicator()

    const [joined, setJoined] = useState(true);
    const [cameraAllowed, setCameraAllowed] = useState(true);

     return (
         <div className={"card p-2 border-[1px] border-purple-800 dark:border-purple-500 md:flex-1"}>
             <div className={"flex-col-center gap-7 my-10"}>
                 <p className={"opacity-60 dark:text-white "}>
                     {/*{ agoraEngine.getRTCStats().Duration? `Call Duration: ${agoraEngine.getRTCStats().Duration}`: "Call Duration: 00:00"}*/}
                 </p>
                 {joined && (
                     <StrictMode>
                         <AgoraRTCProvider client={agoraEngine}>
                             <Call />
                         </AgoraRTCProvider>
                     </StrictMode>
                 )}




             </div>

         </div>

     )
}
