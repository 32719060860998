import CallDetails from "./call-details";
import CallConsultation from "./call-consultation";


export default function OngoingCall({className, image, name, ...props}) {




    return (
        <div
                className={` ${className}  flex flex-col md:flex-row gap-8`}>
                <CallDetails/>


            </div>


    )
}
