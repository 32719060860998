import BaseModal from "../base-modal/base-modal";
import DevicesTable from "../../DemoScreens/devices/list/components/Table";


export default function DeviceModal({
                                         params,
                                         className, isOpen, setIsOpen,
                                         title,
                                         description,
                                         onSelect,
                                        notAssignedOnly,
                                         ...props
                                     }) {


    return (
        <BaseModal innerClassName={"dark:bg-black   lg:w-1/2"} isBoxOpen={isOpen} title={title} autoClose={true}
                   setIsBoxOpen={setIsOpen}>
            <div className={"pt-6"}>
                {isOpen && <DevicesTable
                    onRowSelect={onSelect}
                    notAssignedOnly
                    isModal/>
                }
            </div>
        </BaseModal>


    )
}
