import {combineReducers} from "redux";
import ThemeReducer from "./Reducers/ThemeReducer";
import DrawerReducer from "./Reducers/DrawerReducer";
import AuthReducer from "./Reducers/AuthReducer";


const rootReducer = combineReducers({
    ThemeReducer,
    DrawerReducer,
    AuthReducer,
})


export default rootReducer;