import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AiFillEye, AiOutlineAppstoreAdd} from "react-icons/ai";

import {MdDelete, MdEditNote, MdEmail} from "react-icons/md";

import moment from "moment";
import {BiBookAdd, BiCross, BiPhone, BiX} from "react-icons/bi";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import TableContainer from "../../../components/Table/TableContainer";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import Avatar from "../../../components/Image/Avatar/Avatar";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import {createNoteEmail, getUri, isNoteAvailable} from "../../../utils/utils";
import Table from "../../../components/Table/Table";
import SearchInput from "../../../components/fields/search-input/search-input";
import PaymentVerificationModal from "./PaymentVerificationModal";
import {BsCash} from "react-icons/bs";
import {toast} from "react-toastify";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {is_success} from "../../../API/Axios/Status";
import {CgNotes} from "react-icons/cg";
import EmailModel from "../../../Modals/email-model/email-model";

export default function AppointmentTable({className, isModal, onRowSelect, ...props}) {

    const [data, filter, isLoading, patientActions] = useListApi(URLS.appointmentList, {
        search: "",
    });

    const email = useRef({
        email: null,
        subject: null,
        content: null,
    })
    const [emailPopup, setEmailPopup] = useState(false)


    function InitiateCall(appointment) {
//     check the date and time of the appointment
        function genCallRequest(appointment) {
            let data = {
                appointment: appointment.id
            }
            axiosInstance.post(URLS.initiateCallRequest, data).then((res) => {
                let data = res?.data;
                if (is_success(res?.status)) {
                    toast.success("Call initiated successfully")
                    // redirect to call page
                    window.location.href = `/app/patient-call/${appointment.id}`

                }
            }).catch((err) => {
                toast.error("Error initiating call")

            })


        }

        if (appointment?.date && appointment?.startTime) {
            let date = new Date(appointment.date)
            console.log('date', date)
            let time = appointment.startTime
            // startTime is 02:18 PM format
            time = time.split(" ")[0]
            if (appointment.startTime.split(" ")[1] === "PM") {
                time = time.split(":")
                time[0] = parseInt(time[0]) + 12
                time = time.join(":")
            }

            console.log('time', time)
            date.setHours(time.split(":")[0])
            date.setMinutes(time.split(":")[1])
            // if the date is in the past
            console.log('dateTime', date)

            // if appointment is within the next 5 minutes or 15 past the appointment time
            let now = new Date()
            console.log('now', now)

            let diff = date - now
            console.log('diff', diff)
            if (diff < 0) {
                // appointment time has passed
                // if the appointment time has passed only 15 minutes
                // then the appointment is still valid
                if (Math.abs(diff) < 900000) {
                    // appointment is still valid
                    // initiate the call
                    genCallRequest(appointment)
                } else {
                    toast.error("Appointment time has passed")
                }

            }
            if (diff > 0 && diff < 900000) {
                // appointment is within the next 5 minutes
                genCallRequest(appointment)
            }
            if (diff > 900000) {
                toast.warning("Appointment is not due yet")
            }

        }

    }

    const navigation = useNavigate()

    function onNoteClick(appointment) {
        navigation(`/app/patient/notes`, {
            state: appointment
        })
    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <EmailModel title={"Send Email"}
                        {...email.current}
                        isOpen={emailPopup} setIsOpen={setEmailPopup}/>


            {!isModal && <div className={"w-full flex mb-10 justify-end items-center"}>
                <Link title={"Create Appointment"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"add"}>
                    <BiBookAdd className={"text-base"}/>
                </Link>
            </div>
            }
            {
                <div
                    className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
                >
                    <SearchInput
                        hideRightIcon={true}
                        onChange={(e) => {
                            patientActions.updateFilter({
                                search: e.target.value
                            })
                        }}
                        outerClassName={"border-[1px]  rounded-full"}
                        placeholder={"Search Appointment"}
                    />


                </div>
            }

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Doctor</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell>Date </TableCell>
                            <TableCell>Time </TableCell>
                            <TableCell>Call Type</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Notes </TableCell>
                            <TableCell>Status </TableCell>

                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((row, i) => {
                            let patient = row?.patient;
                            let doctor = row?.doctor;
                            return <TableRow
                                onClick={() => {
                                    if (onRowSelect)
                                        onRowSelect(row)
                                }}
                                className={"cursor-pointer"}
                                key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(doctor?.user?.dp)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{doctor?.user?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(patient?.user?.dp)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{patient?.user?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{

                                        moment(row?.date).format("DD-MM-YYYY")

                                    }</span>
                                </TableCell>


                                <TableCell>
                                    <span className="text-sm">{
                                        row?.startTime + " - " + row?.endTime
                                    }</span>
                                </TableCell>

                                <TableCell>
                                    <span className="text-sm">{row?.media}</span>

                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{row?.call_type}</span>

                                </TableCell>
                                <TableCell>
                                    {
                                        isNoteAvailable(row) &&
                                        <div className={"flex flex-row gap-2"}>
                                            <div onClick={() => {
                                                onNoteClick(row)
                                            }}
                                                 className={"w-10 items-center flex justify-center rounded-full h-10 bg-brand-100 hover:bg-brand-300 "}>
                                                <CgNotes className={"text-brand-700"}/>

                                            </div>
                                            <div onClick={() => {
                                                email.current = {
                                                    email: patient?.user?.email,
                                                    subject: "Appointment Notes",
                                                    content: createNoteEmail(row?.medications, row?.symptoms)
                                                }
                                                setEmailPopup(true)

                                                console.log('email', row)
                                            }}
                                                 className={"w-10 items-center flex justify-center rounded-full h-10 bg-brand-100 hover:bg-brand-300 "}>
                                                <MdEmail className={"text-brand-700"}/>

                                            </div>
                                        </div>
                                    }


                                </TableCell>
                                <TableCell
                                    className={""}

                                >
                                    <span className={`text-sm p-1 rounded-lg
                                    ${row?.status === "PAID" && "text-green-500 border border-green-500"}
                                    ${row?.status === "UNPAID" && "text-red-500 border border-red-500"}
                                    `}>{row?.status}</span>

                                </TableCell>


                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            patientActions.loadNextPage()
                        }}
                        onPrev={(e) => patientActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            patientActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
