import SendEmailComponent from "../../components/send-email-component/send-email-component";
import BaseModal from "../base-modal/base-modal";

export default function EmailModel({
                                       params,
                                       className, isOpen, setIsOpen,
                                       title,
                                       description,
                                       onSelect,
                                       ...props
                                   }) {


    return (
        <BaseModal innerClassName={"dark:bg-black   lg:w-1/2"} isBoxOpen={isOpen} title={title} autoClose={true}
                   setIsBoxOpen={setIsOpen}>

            <div className={"py-8 w-full flex-col flex"}>

                <SendEmailComponent
                    {...props}
                    onSend={() => {
                        setIsOpen(false);
                    }}
                />
            </div>
        </BaseModal>

    )
}