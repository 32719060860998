import PageTitle from "../../components/utils/PageTitle";
import VitalsTable from "./components/vitals-table";
import FormItem from "../../components/Form/FormItem/FormItem";
import {useState} from "react";
import {FaMagnifyingGlass} from "react-icons/fa6";
import {IoHeartOutline} from "react-icons/io5";
import {TiHeartOutline} from "react-icons/ti";
import {BiPulse} from "react-icons/bi";
import {useParams} from "react-router-dom";
import useListApi from "../../Hooks/useListApi";
import URLS from "../../API/Axios/URLS";
import AverageCalculation from "./components/AverageCalculation";



export default function RecentVitals({className, ...props}) {
    let {id} = useParams()

    let dummyData = [];

    for (let i = 0; i < 50; i++) {
        const diastolic = Math.floor(Math.random() * 100) + 60; // Random number between 60 and 160
        const systolic = Math.floor(Math.random() * 100) + 100; // Random number between 100 and 200
        const pulse = Math.floor(Math.random() * 100) + 60; // Random number between 60 and 160

        // Generate a random date between January 2024 and July 2024
        const start = new Date(2024, 0, 1);
        const end = new Date(2024, 6, 31);
        const dateTime = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

        dummyData.push({
            vitals: {
                diastolic: diastolic,
                systolic: systolic
            },
            pulse: pulse,
            device: "BP",
            dateTime: dateTime
        });
    }


    return (
        <div className={` ${className}`}>
            <PageTitle>
                Recent Vitals
            </PageTitle>
            <div className={"flex flex-col md:flex-row gap-5"}>
                <VitalsTable data={dummyData} className={"w-full md:w-2/3"}/>
                <AverageCalculation dummyData={dummyData} className={"w-full md:w-1/3"}/>

            </div>
        </div>

    )
}
