


 export default function TableRow({className,...props}) {

     return (
         <tr className={` ${className}`}  {...props}>
             {
                    props.children
             }
         </tr>

     )
 }