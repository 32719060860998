import BaseModal from "../base-modal/base-modal";

import DoctorTable from "../../DemoScreens/doctor/doctor-list/component/doctor-table";


export default function DoctorsModal({
                                         params,
                                         className, isOpen, setIsOpen,
                                         title,
                                         description,
                                         onSelect,
                                         ...props
                                     }) {


    return (
        <BaseModal innerClassName={"dark:bg-black   lg:w-1/2"} isBoxOpen={isOpen} title={title} autoClose={true}
                   setIsBoxOpen={setIsOpen}>
            <div className={"pt-6"}>
                <DoctorTable
                    onRowSelect={onSelect}
                    isModal/>

            </div>
        </BaseModal>


    )
}