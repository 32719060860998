import CallRequestTable from "./call-request-table";

export default function CallRequest({className, ...props}) {

    return (
        <div className={` ${className} py-4`}>

                <CallRequestTable />

        </div>

    )
}