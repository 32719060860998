import {useEffect, useState} from "react";
import FormButton from "../Form/Button/FormButton";
import FormItem from "../Form/FormItem/FormItem";
import useForm from "../../Hooks/useForm";
import {toast} from "react-toastify";


export default function SendEmailComponent({className, onSend, ...props}) {
    console.log(props)
    const [form, error, loading, setFormData, actions] = useForm({
        email: props?.email || "",
        subject: props?.subject || "",
        content: props?.content || "",
    })

    function submitForm() {
        actions.post("auth/send-email").then((response) => {
            toast.success("Email Sent Successfully", {
                toastId: "email-send"
            });
        }).catch((error) => {
            toast.error("Error in sending email", {
                toastId: "email-send"
            });
        }).finally(() => {
            onSend && onSend();
        })
    }

    useEffect(() => {
        setFormData({
            email: props?.email || "",
            subject: props?.subject || "",
            content: props?.content || "",
        });
    }, [props.email, props.subject, props.content]);
    return (
        <form className={` flex flex-col gap-2 ${className}`}>

            <FormItem
                label={"Email"}
                message={error?.email}
                variant={"auth"}
                type={"error"}

            >
                <input
                    type="email"
                    placeholder="Email"
                    value={form?.email}
                    onChange={(e) => setFormData({...form, email: e.target.value})}
                />

            </FormItem>

            <FormItem
                label={"Subject"}
                message={error?.subject}
                variant={"auth"}
                type={"error"}

            >
                <input
                    type="text"
                    placeholder="Subject"
                    value={form?.subject}
                    onChange={(e) => setFormData({...form, subject: e.target.value})}
                />
            </FormItem>

            <FormItem
                label={"Content"}
                message={error?.content}
                variant={"auth"}
                type={"error"}

            >
                <textarea
                    placeholder="Subject"
                    value={form?.content}
                    className={"h-[250px]"}
                    onChange={(e) => setFormData({...form, content: e.target.value})}
                />
            </FormItem>

            <FormButton
                onClick={() => submitForm()}
                loading={loading}
            >
                Send Email
            </FormButton>
        </form>

    )
}