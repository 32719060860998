import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import OtpInput from "../../../components/fields/OtpInput/OtpInput";
import FormButton from "../../../components/Form/Button/FormButton";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {HTTP_400_BAD_REQUEST, is_success} from "../../../API/Axios/Status";
import FormNoneFieldErrors from "../../../components/Form/FormNoneFieldErrors";
import {useSession} from "../../../redux/Reducers/AuthReducer";


export default function LoginOTP({...props}) {
    const {state} = useLocation();
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        otp: null
    });
    const {authActions} = useSession();

    const navigate = useNavigate();

    if (!state?.email) {
        navigate("/auth/login")
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        setError({})

        axiosInstance.post(URLS.LoginOtpAuthnticate, {
            email: state?.email,
            otp: otp
        }).then((res) => {
            if (is_success(res?.status)) {
                let data = res?.data;
                toast("OTP Verified!", {type: "success"})
                navigate("/auth/new-password", {state: {email: state?.email, otp: otp}})
                authActions.setSession(data);
                let role = data?.profile?.roles

                if (role.includes("patient")) {
                    navigate("/app/appointments");
                } else if (role.includes("doctor")) {
                    navigate("/app/dashboard-doctor");
                } else if (role.includes("admin")) {
                    navigate("/app/dashboard");
                }
            }
            setLoading(false);
        }).catch((err) => {
            toast("Invalid OTP", {type: "error"})
            if (err?.response?.status === HTTP_400_BAD_REQUEST) {
                setError(err?.response?.data);
            } else {
                setError({
                    non_field_errors: ["Something went wrong, please try again later."]
                })
            }
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })

    }

    return (
        <div className="mt-[10vh] w-full max-w-full gap-0 flex-col items-center flex md:pl-4 lg:pl-0 xl:max-w-[480px]">
            <h4 className="mb-2.5 text-3xl md:text-3xl lg:text-4xl font-bold text-navy-700 dark:text-white">
                Otp Verification
            </h4>
            <p className=" ml-1 text-sm  md:text-base text-gray-400">
                Enter the OTP sent to your email address.
            </p>
            <p className="mb-9 ml-1 text-sm  md:text-base text-gray-400">
                If you have DND on your phone, <span className={"text-purple-900"}> check your Email for OTP</span>
            </p>
            <OtpInput
                value={otp}
                onChange={setOtp}
                error={error?.otp}
                numInputs={4}
                shouldAutoFocus={true}
                placeholder={'-'}

            />

            <FormNoneFieldErrors errors={error?.non_field_errors} className={"mt-2"}/>
            <FormButton
                loading={loading}
                type={"submit"}
                onClick={handleSubmit}
            >
                Verify
            </FormButton>

        </div>
    )
}