export const isLocalhost = window.location.hostname === "localhost" || window.location.hostname.includes("192.168")

export const isProduction = true

export const PRODUCTION_URL = "https://dev.healthboxes.com/";
export const DEVELOPMENT_URL = "http://localhost:8000/";
export const BASE_URL = isProduction ? PRODUCTION_URL : DEVELOPMENT_URL;

export const CURRENCY = "NGN";
export const APP_NAME = "Health Box";

export const GOOGLE_MAP_API_KEY = "AIzaSyDPvCZmeGuN-y_pJ3ug7PYrwp6BHyKnNUY";

