import React, {useEffect, useState} from "react";
import {FaUser} from "react-icons/fa";
import IconCard from "../../../../components/Cards/IconCards/IconCard";
import {MdOutlinePayment} from "react-icons/md";
import {PiArrowCounterClockwiseBold, PiCloudLightningBold, PiContactlessPayment} from "react-icons/pi";
import {CgUser} from "react-icons/cg";
import {BsClipboard2, BsClipboard2Fill, BsClipboardData} from "react-icons/bs";
import axiosInstance from "../../../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";

export default function DoctorAppointmentCard({className, id, ...props}) {
    const [data, setData] = useState({
        "totalAppointments": 0,
        "upcomingAppointments": 0,
        "completedAppointments": 0,
        "payments": {
            "paid": 0,
            "unpaid": 0,
        },
        "appointments": 0,
        "patientCount": 0,
    });

    useEffect(() => {
        axiosInstance.post(`doctors/dashboard?id=${id}`, {
            params: {
                id: id
            }
        }).then(res => {
            setData(old => {
                return res?.data?.data || data
            })
        }).catch(err => {
            toast.error(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong", {
                toastId: "doctor-appointment-card-error"
            })
        })

    }, [id])
    return (
        <div className={` ${className} card p-4 `}>
            <div className={"grid grid-cols-2 gap-3 "}>
                <IconCard
                    title={"Total Appointments"}
                    color={"#ff058e"}
                    icon={<BsClipboardData className={"text-2xl"}/>}
                    value={data?.totalAppointments}
                />
                <IconCard
                    title={"Upcoming Appointments"}
                    color={"rgba(242,93,251,0.7)"}
                    icon={<BsClipboard2 className={"text-2xl"}/>}
                    value={data?.upcomingAppointments}
                />
                <IconCard
                    title={"Completed Appointments"}
                    color={"#0a50ee"}
                    icon={<BsClipboard2Fill className={"text-2xl"}/>}
                    value={data?.completedAppointments}
                />
                <IconCard
                    title={"Total Paid Payments"}
                    color={"#eead0a"}
                    icon={<MdOutlinePayment className={"text-2xl"}/>}
                    value={data?.payments?.paid}
                />
                <IconCard
                    title={"Total UnPaid Payments"}
                    color={"#af664d"}
                    icon={<PiContactlessPayment className={"text-2xl"}/>}
                    value={data?.payments?.paid}
                />

                <IconCard
                    title={"Total Patients"}
                    color={"#910f50"}
                    icon={<CgUser className={"text-2xl"}/>}
                    value={data?.patientCount}
                />

            </div>
        </div>

    )
}
