import PageTitle from "../../../components/utils/PageTitle";
import WeekScheduleTable from "./WeekScheduleTable";


export default function Schedule({className, ...props}) {
    return (
        <div className={` ${className}`}>
            <PageTitle>
                Week Schedule
            </PageTitle>
            <WeekScheduleTable/>

        </div>
    )
}
