import BaseModal from "../base-modal/base-modal";
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import FormButton from "../../components/Form/Button/FormButton";
import {useState} from "react";
import axiosInstance from "../../API/Axios/AxiosConfig";
import URLS from "../../API/Axios/URLS";
import {toast} from "react-toastify";
import moment from "moment";

export default function DoctorReportModal({className, doctorId, isOpen, setIsOpen, ...props}) {
    const [state, setState] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [isLoading, setIsLoading] = useState(false)

    function DownloadCSV() {
        setIsLoading(true)
        toast.promise(axiosInstance.get(URLS.DoctorReport, {
            responseType: 'blob',
            params: {
                start_date: moment(state.startDate).format("YYYY-MM-DD"),
                end_date: moment(state.endDate).format("YYYY-MM-DD"),
                doctor: doctorId
            }
        }), {
            loading: "Downloading...",
            success: "Records Downloaded",
            error: "Failed to download csv"
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Doctor_report.csv'); // or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {

        }).finally(() => {
            setIsLoading(false)
            setIsOpen(false)
        })
    }

    return (
        <BaseModal innerClassName={"dark:bg-black  w-auto  "} isBoxOpen={isOpen} title={"Download Record"}
                   autoClose={true}
                   setIsBoxOpen={setIsOpen}>

            <div className={"justify-center items-center flex-col gap-4 flex"}>

                <DateRangePicker
                    ranges={[
                        {
                            ...state,
                            key: 'calendar'
                        }
                    ]}
                    months={2}

                    direction="horizontal"

                    onChange={(item) => {
                        setState({
                            startDate: item.calendar.startDate,
                            endDate: item.calendar.endDate,
                        })

                    }}
                />


                <div className={"inline-flex gap-4"}>
                    <FormButton
                        loading={isLoading}
                        onClick={DownloadCSV}
                        className={"min-w-[300px]"}>
                        Download Report
                    </FormButton>
                </div>
            </div>
        </BaseModal>

    )
}