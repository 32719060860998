import {FaFileDownload} from "react-icons/fa";
import {IoEye} from "react-icons/io5";
import {getUri} from "../../../../utils/utils";
import {Link} from "react-router-dom";

function FileItem({className, file, ...props}) {

    return (
        <div className={`flex flex-row-center border-b-[1px] dark:border-gray-700 pb-4  ${className}`}>
            <p className={"flex-1 text-md dark:text-white opacity-60"}>
                {
                    file?.name
                }
            </p>
            <IoEye
                onClick={() => {
                    let url = getUri(file?.file);
                    window.open(url, '_blank');

                }}
                className={"text-blue-500 cursor-pointer dark:text-blue-300"}
            />
        </div>

    )
}

export default function PatientFiles({className, data, email, ...props}) {

    return (
        <div className={` ${className} dark:text-white  card  p-4`}>
            <div className={"flex flex-col gap-4"}>
                <div className={"flex flex-row gap-2 justify-center items-center"}>
                    <p className={"font-bold dark:opacity-60 flex-1 text-xl"}>
                        Patient Files/Documents
                    </p>
                    <Link to={"/app/document-manager/" + email} className={"tex-sm underline cursor-pointer"}>
                        View All
                    </Link>
                </div>
                <div className={"flex flex-col max-h-[200px] overflow-hidden gap-4"}>
                    {
                        data?.results?.map((file, index) => {
                            return <FileItem key={index} file={file}/>
                        })
                    }

                </div>
            </div>
        </div>

    )
}
