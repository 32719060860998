import {useEffect, useState} from "react";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import FormItem from "../../../components/Form/FormItem/FormItem";
import {FaMagnifyingGlass, FaWeightScale} from "react-icons/fa6";
import {TiHeartOutline} from "react-icons/ti";
import {BiPulse} from "react-icons/bi";
import {useParams} from "react-router-dom";
import {FaWeight} from "react-icons/fa";
import {GiBlood, GiChemicalDrop} from "react-icons/gi";
import {SiOxygen} from "react-icons/si";
import useForm from "../../../Hooks/useForm";


function AverageCalculation({className, dummyData, ...props}) {
    let {id} = useParams()

    const [form, error, loading, setFormData, actions] = useForm({

        patientId: id,
        entryCount:10,
    });
    let [deviceData, filter, isLoading, deviceActions] = useListApi(URLS.AssignedDevices, {
        patientId: id,
        showUnique: true
    });
    const [calculatedData, setCalculatedData] = useState()

    function calculateAverage() {
       actions.get(URLS.getAverageData).then(res => {
            setCalculatedData(res?.data?.data)
        })
    }

    useEffect(() => {
        setFormData({...form, deviceType: deviceData?.results[0]?.device?.deviceType})

    }, [deviceData]);
    function getDeviceName(device) {
        if (device === "bp") {
            return "Blood Pressure"
        } else if (device === "sugar") {
            return "Sugar"
        } else if (device === "weight") {
            return "Weight Machine"
        } else if (device === "sp02") {
            return "SP02"
        } else {
            return "Device"
        }
    }

    return (
        <div
            className={`w-full h-full bg-white dark:text-darkTheme-100 overflow-auto dark:bg-darkTheme-800 rounded-lg ${className}`}>
            <div className={"w-full  p-5 rounded-lg"}>
                <div className={"text-xl text-brand-700 text-center dark:text-brand-200  font-bold"}>
                    Average Calculation
                </div>
                <div className={"flex flex-1 gap-2"}>
                    <FormItem
                        className={"w-3/4"}
                        label={"Device"}
                        type={"error"}
                        variant={"auth"}
                    >
                        <select
                            required={true}
                            value={form.deviceType}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer w-full"}
                            onChange={(e) => {
                                setFormData({...form, deviceType: e.target.value})
                            }}
                        >
                            {
                                deviceData?.results?.map((item, index) => {
                                    return <option key={index}
                                                   value={item?.device?.deviceType}>{getDeviceName(item?.device?.deviceType)}</option>
                                })
                            }
                        </select>
                    </FormItem>
                    <FormItem
                        className={"w-3/4"}
                        label={"Calculate Upto"}
                        type={"error"}
                        variant={"auth"}
                    >
                        <select
                            required={true}
                            value={form.entryCount}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer w-full"}
                            onChange={(e) => {
                                setFormData({...form, entryCount: e.target.value})
                            }}
                        >
                            <option className={"text-base"} value={10}>Recent 10 Entries</option>
                            <option className={"text-base"} value={20}>Recent 20 Entries</option>
                            <option className={"text-base"} value={30}>Recent 30 Entries</option>
                            <option className={"text-base"} value={40}>Recent 40 Entries</option>
                            <option className={"text-base"} value={50}>Recent 50 Entries</option>
                        </select>
                    </FormItem>
                    <div className={"w-1/3 flex items-end "}>
                        <FaMagnifyingGlass
                            className={"text-brand-700 h-12 bg-brand-500 w-12 p-2 rounded-full cursor-pointer"}
                            onClick={() => {
                                calculateAverage()
                            }}
                        />
                    </div>

                </div>
                {
                    calculatedData && <div className={"flex flex-col gap-5 mt-5"}>
                        {calculatedData?.device === "bp" &&
                            <div className={"flex flex-1 flex-col items-center gap-y-8 justify-center"}>
                                <TiHeartOutline className={"text-[100px] text-brand-700"}/>

                                <p>
                                    Recent <span className={"font-bold"}>{calculatedData?.entryCount}</span> Blood Pressure
                                    Readings
                                </p>
                                <p className={"text-4xl"}>
                                <span
                                    className={"font-semibold"}>{calculatedData?.sys} / {calculatedData?.dia}</span> mmHg
                                </p>

                                <div className={"flex flex-1 flex-col items-center justify-center"}>
                                    <BiPulse className={"text-[100px] text-brand-700"}/>


                                    <p className={"text-4xl"}>
                                <span
                                    className={"font-semibold"}>{calculatedData?.pul}</span> BPM
                                    </p>

                                </div>

                            </div>
                        }


                        {
                            calculatedData?.device === "weight" &&
                            <div className={"flex flex-1 flex-col items-center gap-y-8 justify-center"}>

                                <FaWeight className={"text-[100px] text-brand-700"}/>

                                <p>
                                    Recent <span className={"font-bold"}>{calculatedData?.entryCount}</span> Weight Readings
                                </p>
                                <p className={"text-4xl"}>
                                <span
                                    className={"font-semibold"}>{calculatedData?.weight}</span> Kg
                                </p>

                            </div>
                        }
                        {
                            calculatedData?.device === "sugar" &&
                            <div className={"flex flex-1 flex-col items-center gap-y-8 justify-center"}>

                                <GiChemicalDrop className={"text-[100px] text-brand-700"}/>

                                <p>
                                    Recent <span className={"font-bold"}>{calculatedData?.upto}</span> Sugar Level Readings
                                </p>
                                <p className={"text-4xl"}>
                            <span
                                className={"font-semibold"}>{calculatedData?.sugar}</span> mg/dL
                                </p>

                            </div>
                        }

                        {
                            calculatedData?.device === "sp02" &&
                            <div className={"flex flex-1 flex-col items-center gap-y-8 justify-center"}>

                                <GiChemicalDrop className={"text-[100px] text-brand-700"}/>

                                <p>
                                    Recent <span className={"font-bold"}>{calculatedData?.upto}</span> SP02 Readings
                                </p>
                                <p className={"text-4xl"}>
                            <span
                                className={"font-semibold"}>83.5</span> %
                                </p>

                            </div>
                        }
                    </div>
                }


            </div>

        </div>
    )

}

export default AverageCalculation;
