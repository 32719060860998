
import { RotatingLines } from 'react-loader-spinner'
import BaseModal from "../../../Modals/base-modal/base-modal";
import useForm from "../../../Hooks/useForm";
import {useEffect} from "react";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";


export default function PaymentVerificationModal({
                                         params,
                                         className, isOpen, setIsOpen,
                                         title,
    response,

                                         description,
                                         onSelect,
                                         ...props
                                     }) {

    const [form, error, loading, setFormData, actions] = useForm()
    useEffect(() => {
        if(response?.data?.id) {
            setFormData({
                id:response?.data?.id
            })
        }

    }, [response])


    return (
        <BaseModal innerClassName={"dark:bg-black   lg:w-1/2"} isBoxOpen={isOpen} title={title} autoClose={true}
                   setIsBoxOpen={setIsOpen}>
            <div className={"pt-6 flex flex-1 w-full items-center justify-center"}>
                <div>
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeWidth="5"
                        strokeColor={"#f97316"}
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass="items-center justify-center"
                    />
                </div>


            </div>
            <div className={"w-full text-center mt-10"}

            >
                <button
                    disabled={loading}
                    onClick={() => actions.post(URLS.verifyPayment, (data) => {
                        return {
                            id: form.id
                        }
                    }).then((res) => {
                        if (res.status === 200) {
                            toast.success("Payment Verified")
                            setTimeout(()=>{
                                window.location.href = "/app/appointments"
                            },1500)
                        }
                        else{
                            toast.error("Failed to verify payment. Please try again if paid.")
                        }
                    })
                }
                    className={`p-4 bg-orange-500 rounded-lg text-white cursor-pointer
                    ${loading ? "opacity-50" : ""}
                    `}>Verify Payment</button>

            </div>
            <div className={"w-full flex flex-row-reverse mt-4 border-t-2 pt-4 text-sm "}>
                <a href={response?.payment?.data?.authorization_url || response?.payment?.data?.payment_url } target={"_blank"} className={"text-blue-500"}>Click here if payment window didn't open automatically</a>

            </div>
        </BaseModal>


    )
}
