import React, {useEffect, useState} from "react";
import {BsFillMoonStarsFill} from "react-icons/bs";
import day from "./dayMode.png"
import night from "./night.png"
import {IoMdSunny} from "react-icons/io";
import {useTheme} from "../../../redux/Reducers/ThemeReducer";

export default  function DarkModeButton({...props}) {
    const {isDarkMode, toggleTheme} = useTheme();

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

    }, [isDarkMode])


    return (
        <div
            style={{
                backgroundImage: `url(${isDarkMode ? night : day})`,

                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
            className={"absolute bottom-0 right-0 flex bg-brand-500 rounded-full dark:bg-darkBgTertiary  justify-center items-center  cursor-pointer  w-12  h-12   m-2 "}
            onClick={toggleTheme}

        >
            {isDarkMode ?
                <BsFillMoonStarsFill className={"text-white text-base  cursor-pointer"}/>
                :
                <IoMdSunny className={"text-yellow-500 text-base   cursor-pointer"}/>
            }

        </div>
    )
}
