import { EncryptionMode, UID, SDK_MODE } from "agora-rtc-sdk-ng";

const config = {
  uid: 0,
  appId: "1f519685b8b04046b087a0785899746f",
  channelName: "KFCKWFJQDK",
  rtcToken: "0061f519685b8b04046b087a0785899746fIAAsMNWUJJdAlcao/fICdXi7lRuZubaUMG6ok0uHw4+RXkBxsuMNvtUaIgC4IDcAygZjZgQAAQDOuGFmAgDOuGFmAwDOuGFmBADOuGFm",
  serverUrl: "",
  proxyUrl: "http://localhost:8080/",
  tokenExpiryTime: 600,
  token: "",
  encryptionMode: "aes-128-gcm2",
  salt: "",
  encryptionKey: "",
  destChannelName: "",
  destChannelToken: "",
  destUID: 2,
  secondChannel: "",
  secondChannelToken: "",
  secondChannelUID: 2,
  selectedProduct: "rtc"
};

export default config;
