import PageTitle from "../../components/utils/PageTitle";
import React from "react";
import OngoingCall from "./components/ongoing-call";
import CallRequest from "./components/call-request";
import {useParams} from "react-router-dom";


export default function Calls({className, ...props}) {
    const {id} = useParams();

    return (
        <div className={` ${className}`}>
            <PageTitle>
                Calls
            </PageTitle>
            <div className={"flex flex-col gap-5"}>
                {
                    id && <OngoingCall
                    />
                }

                <CallRequest />

            </div>
        </div>

    )
}
