import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import Checkbox from "../../../components/checkbox";
import GoogleButton from "../../../components/Buttons/GoogleButton/GoogleButton";
import {toast} from "react-toastify";
import FormNoneFieldErrors from "../../../components/Form/FormNoneFieldErrors";
import {useSession} from "../../../redux/Reducers/AuthReducer";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import URLS from "../../../API/Axios/URLS";
import {HTTP_400_BAD_REQUEST, is_success} from "../../../API/Axios/Status";

export default function Login({className, ...props}) {
    const [error, setError] = useState({});
    const [form, setForm] = useState({
        email: "",
        password: "",
        isRemember: false,
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError({})
        axiosInstance.post(URLS.login, form).then((res) => {
            let data = res?.data;
            if (is_success(res?.status)) {
                toast(res?.data?.details, {
                    type: "success"
                });

                navigate("/auth/login-otp", {state: {email: form.email}})

            }
            setLoading(false);
        }).catch((err) => {
            toast("Login Failed", {
                type: "error"
            })
            if (err?.response?.status === HTTP_400_BAD_REQUEST) {
                setError(err?.response?.data);
            } else {
                setError({
                    non_field_errors: ["Something went wrong, please try again later."]
                })
            }

        }).finally(() => setLoading(false))


    }


    return (
        <form onSubmit={handleSubmit}
              className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                Sign In
            </h4>
            <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your email and password to sign in!
            </p>
            {/*<GoogleButton className={"my-4"}/>*/}

            {/*<div className={"flex flex-row  mb-4 justify-center items-center gap-2 text-sm text-gray-400"}>*/}
            {/*    <div className={"line flex-1"}/>*/}
            {/*    OR*/}
            {/*    <div className={"line flex-1"}/>*/}
            {/*</div>*/}

            <FormItem
                label={"Email"}
                type={"error"}
                className={"mb-2"}
                message={error?.email}
                variant={"auth"}
            >
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={form.email}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            email: e.target.value
                        })
                    }}
                    required
                    placeholder="Email"
                />


            </FormItem>

            <FormItem
                label={"Password"}
                type={"error"}
                message={error?.password}
                variant={"auth"}
            >
                <input
                    type="password"
                    name="password"
                    id="password"
                    value={form.password}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            password: e.target.value
                        })
                    }}
                    required
                    placeholder="********"
                />


            </FormItem>

            <FormNoneFieldErrors errors={error?.non_field_errors} className={"mb-4 mt-2"}/>


            {/* Checkbox */}
            <div className="mb-4 mt-4 flex items-center justify-between px-2">
                <div className="flex items-center">
                    {/*<Checkbox*/}
                    {/*    checked={form.isRemember}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        setForm({*/}
                    {/*            ...form,*/}
                    {/*            isRemember: e.target.checked*/}
                    {/*        })*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">*/}
                    {/*    Keep me logged In*/}
                    {/*</p>*/}
                </div>
                <Link
                    to={"/auth/forget-password"}
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                    Forgot Password?
                </Link>
            </div>
            <FormButton
                loading={loading}
                type={"submit"}
            >
                Sign In
            </FormButton>
            <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
                <Link
                    to={"/auth/patient-register"}

                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                    Create an account
                </Link>
            </div>
        </form>

    )
}
