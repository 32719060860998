import React, {useState, useEffect} from 'react'
import CTA from "../../components/utils/CTA";
import IconCard from "../../components/Cards/IconCards/IconCard";
import {BiLogoAndroid} from "react-icons/bi";
import ClientTable from "./tables/ClientTable/ClientTable";
import PageTitle from "../../components/utils/PageTitle";
import ChartCard from "../../components/Cards/ChartCard/ChartCard";
import {Bar, Doughnut, Line} from "react-chartjs-2";
import {
    barLegends,
    barOptions,
    doughnutLegends,
    doughnutOptions,
    lineLegends,
    lineOptions
} from "./variabales/chartData";
import ChartLegend from "../../components/utils/ChartLegend";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ArcElement,
    BarElement,
    Legend,
} from 'chart.js';
import {HiOutlineZoomIn} from "react-icons/hi";
import CallRequestCard from "../../components/base/call-request-card/call-request-card";
import dummyCallRequest from "../../utils/dummy-data/dummy-call-request";
import {Link} from "react-router-dom";
import CallRequest from "../calls/components/call-request";
import useForm from "../../Hooks/useForm";
import URLS from "../../API/Axios/URLS";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
);


export default function DashboardDoctor() {
    const [form, error, loading, setFormData, actions]  = useForm()
    useEffect(() => {
        actions.post(URLS.DoctorDashboard).then((data) => {
            setFormData(data.data.data)
        }).catch((error) => {
            console.log(error)
        })

    }, [])


    return (
        <div>
            <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Dashboard</h1>
            <div className={"flex flex-row  gap-5"}>
                <div className={"flex-1"}>
                    <div className={"grid gap-2  grid-cols-1 md:grid-cols-2  xl:grid-cols-4"}>
                        <IconCard
                            title={"Total Patients"}
                            color={"#0a50ee"}
                            icon={<BiLogoAndroid className={"text-2xl"}/>}
                            value={form?.patientCount}
                        />
                        <IconCard
                            title={"Total Appointments"}
                            color={"#ff01c9"}
                            icon={<BiLogoAndroid className={"text-2xl"}/>}
                            value={form?.appointments}
                        />
                        <IconCard
                            title={"Completed Appointments"}
                            color={"#ff01c9"}
                            icon={<BiLogoAndroid className={"text-2xl"}/>}
                            value={form?.completedAppointments}
                        />
                        <IconCard
                            title={"Upcoming Appointments"}
                            color={"#00000"}
                            icon={<BiLogoAndroid className={"text-2xl"}/>}
                            value={form?.upcomingAppointments}
                        />
                        {/*<IconCard*/}
                        {/*    title={"Total Traffic"}*/}
                        {/*    color={"#427dff"}*/}
                        {/*    icon={<BiLogoAndroid className={"text-2xl"}/>}*/}
                        {/*    value={"1000"}*/}
                        {/*/>*/}
                        {/*<IconCard*/}
                        {/*    title={"Total Traffic"}*/}
                        {/*    color={"#5d0072"}*/}
                        {/*    icon={<BiLogoAndroid className={"text-2xl"}/>}*/}
                        {/*    value={"1000"}*/}
                        {/*/>*/}

                    </div>

                    <CallRequest />
                </div>
                {/*<div className={"hidden  flex-row lg:flex w-[350px]"}>*/}
                {/*    <div*/}
                {/*        className={"w-full flex flex-col  gap-4 rounded-md bg-white dark:bg-gray-800 dark:text-white  shadow-sm"}>*/}
                {/*        <div className={"flex px-6 justify-center items-center flex-row gap-2"}>*/}
                {/*            <p className={"font-semibold flex-1 text-xl py-3"}>*/}
                {/*                Call Request*/}
                {/*            </p>*/}

                {/*            <Link*/}
                {/*                to={"/app/calls"}*/}
                {/*                className={"cursor-pointer dark:text-purple-500 text-purple-900 font-semibold text-sm"}>*/}
                {/*                See All*/}
                {/*            </Link>*/}


                {/*        </div>*/}
                {/*        /!*<div className={"max-h-[60vh] px-8 overflow-auto"}>*!/*/}
                {/*        /!*    {*!/*/}
                {/*        /!*        dummyCallRequest.map((item, index) => {*!/*/}
                {/*        /!*            return (*!/*/}
                {/*        /!*                <CallRequestCard key={index} {...item}/>*!/*/}
                {/*        /!*            )*!/*/}
                {/*        /!*        })*!/*/}
                {/*        /!*    }*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>

    )
}
