import {useEffect, useState} from "react";
import FormItem from "../../../components/Form/FormItem/FormItem";
import {toast} from "react-toastify";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import {useLocation} from "react-router-dom";


function TimeSlot({className,data,formData,setFormData,deleteHovered, setDeleteHovered,...props}) {
    let startTime = data.startTime
    let endTime = data.endTime

    // if time is in 24 hour format then convert it to 12 hour format
    if(startTime.length === 5){
        let [hour,minute] = startTime.split(":")
        let suffix = "AM"
        if(hour >12){
            hour = hour - 12
            suffix = "PM"
        }
        if (hour === 12){
            suffix = "PM"
        }
        startTime = `${hour}:${minute} ${suffix}`
    }
    if(endTime.length === 5){
        let [hour,minute] = endTime.split(":")
        let suffix = "AM"
        if(hour > 12){
            hour = hour - 12
            suffix = "PM"
        }
        if (hour === 12){
            suffix = "PM"
        }
        endTime = `${hour}:${minute} ${suffix}`
    }

    return (
        <div
            onMouseEnter={() => {
                setDeleteHovered(data)
            }}
            onMouseLeave={() => {
                setDeleteHovered()

            }}
            className={"w-full cursor-pointer text-center items-center my-1 p-2 flex  rounded border-2 border-orange-500  transition ease-in-out  "}
        >
            <p className={" flex-1 items-center text-center text-orange-500 font-semibold"}>{startTime} - {endTime}</p>
            {
                deleteHovered === data && <div
                    onClick={() => {
                        let tempFormData = [...formData]
                        tempFormData = tempFormData.filter((item) => {
                            return item !== data
                        })
                        setFormData(tempFormData)
                    }}
                    className={" bg-red-600 dark:bg-red-600 dark:text-white rounded-full w-10 h-10 shadow-inner p-2 text-white"}>
                    X
                </div>
            }

        </div>
    )
}

export default function WeekScheduleTable({className, ...props}) {

    const [days, setDays] = useState(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])
    const [daySelected, setDaySelected] = useState("")
    const [hovered, setHovered] = useState("")
    const [tempData, setTempData] = useState({})
    const [deleteHovered, setDeleteHovered] = useState("")
    const [formData, error, loading, setFormData, actions] = useForm()
    const {state} = useLocation();
    const {doctor} = state
    useEffect(() => {
        if(doctor) {
            actions.get(URLS.getDoctorSchedule+"?doctorId="+doctor?.id).then((res) => {
                if (res.status === 200) {
                    setFormData(res.data.data)
                }
            })
        }
    }, [doctor]);

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            {
                !daySelected &&
                <div className={"w-full flex flex-row-reverse"}>
                <button
                    className={"bg-orange-500 w-36 text-white p-2 rounded"}
                    onClick={() => {
                        actions.post(URLS.saveDoctorSchedule+"?doctorId="+doctor?.id).then((res) => {
                            if (res.data.status === 200) {
                                toast.success("Schedule saved")
                                setFormData(res.data.data)
                            }
                        }).catch((err) => {
                            toast.error("Error saving schedule")
                        })
                    }}
                >Save
                </button>

            </div>}

            {
                !daySelected &&
                <div>
                    <div className={"flex justify-between border-b-2 px-4 dark:text-white py-4"}>
                        {
                            days?.map((day) => {

                                return <div
                                    onMouseEnter={() => {
                                        setHovered(day)
                                    }}
                                    onMouseLeave={() => {
                                        setHovered("")

                                    }}
                                    className={"w-full cursor-pointer text-left hover:bg-orange-500 hover:shadow-xl hover:shadow-orange-400 p-2 flex flex-wrap justify-between hover:rounded hover:text-white transition ease-in-out  hover:-translate-y-1"}

                                ><p className={"flex-1 text-center"}>{day}</p>
                                    {
                                        hovered === day && <div
                                            className={" bg-white dark:bg-gray-700 dark:text-white rounded-lg shadow-inner p-2 text-black"}>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        setDaySelected(day)
                                                    }}>Select
                                                </button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            })
                        }


                    </div>

                    <div className={"flex justify-between px-4 dark:text-white"}>
                        {
                            days?.map((day) => {

                                return (
                                    <div className={"w-full p-2 gap-2"}>
                                        {

                                            formData?.map((data, i) => {
                                                if(data.day !== day){
                                                    return null
                                                }
                                                return (
                                                    <TimeSlot
                                                        data={data}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        deleteHovered={deleteHovered}
                                                        setDeleteHovered={setDeleteHovered}
                                                    />
                                                )

                                            })
                                        }


                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }

            {
                daySelected && <div>
                    <div className={"flex justify-between border-b-2 px-4 dark:text-white py-4"}>
                        <div

                            className={"w-full flex cursor-pointer text-left bg-orange-500 shadow-xl shadow-orange-400 p-2  flex-wrap justify-between rounded text-white transition ease-in-out  "}

                        >
                            <p className={"flex flex-1 bg items-center justify-center text-center"}>
                                {daySelected}
                            </p>
                            <div
                                className={" bg-white dark:bg-gray-700 dark:text-white rounded-lg shadow-inner p-2 text-black"}>
                                <div>
                                    <button
                                        onClick={() => {
                                            setDaySelected("")
                                        }}>Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"w-full justify-center items-center flex "}>
                        <div className={"mt-10 flex-col w-full lg:w-1/2 gap-2 p-2 rounded shadow bg-white self-center"}>

                            <div className={"w-full border-b-2 p-2 gap-2"}>
                                {
                                    formData &&
                                    formData?.map((data, i) => {
                                        if(data.day !== daySelected){
                                            return null
                                        }
                                        return (
                                            <TimeSlot
                                                data={data}
                                                formData={formData}
                                                setFormData={setFormData}
                                                deleteHovered={deleteHovered}
                                                setDeleteHovered={setDeleteHovered}
                                            />
                                        )

                                    })
                                }


                            </div>

                            <div className={"flex flex-1 gap-2"}>
                                <FormItem
                                    label={"Start Time"}
                                    type={"error"}
                                    className={"w-1/2"}

                                    variant={"auth"}
                                    message={""}
                                >
                                    <input
                                        required={true}
                                        value={tempData?.startTime}

                                        type={"time"}
                                        onChange={(e) => {
                                            let tmp = {...tempData}
                                            tmp.startTime = e.target.value
                                            setTempData(tmp)
                                        }}
                                    />
                                </FormItem>

                                <FormItem
                                    label={"End Time"}
                                    type={"error"}
                                    className={"w-1/2"}

                                    variant={"auth"}
                                    message={""}
                                >
                                    <input
                                        placeholder={""}
                                        required={true}
                                        defaultValue={""}
                                        value={tempData?.endTime }

                                        type={"time"}
                                        onChange={(e) => {
                                            let tmp = {...tempData}
                                            tmp.endTime = e.target.value
                                            setTempData(tmp)
                                        }}
                                    />
                                </FormItem>
                            </div>
                            <button
                                className={"w-full bg-orange-500 text-white p-2 rounded mt-4"}
                                onClick={() => {
                                    // if start time is greater than end time
                                    if(!tempData.startTime || !tempData.endTime){
                                        toast.error("Start time and end time is required")
                                        return
                                    }
                                    if (tempData.startTime > tempData.endTime) {
                                        toast.error("Start time cannot be greater than end time")
                                        return
                                    }
                                    let isOverlapping = false
                                    formData?.forEach((data) => {
                                        if (data.day ===daySelected &&((tempData.startTime >= data.startTime && tempData.startTime <= data.endTime) || (tempData.endTime >= data.startTime && tempData.endTime <= data.endTime))) {
                                            isOverlapping = true
                                        }
                                    })

                                    if (isOverlapping) {
                                        toast.error("Time is overlapping")
                                        return
                                    }
                                    let tmp = []
                                    if (formData) {
                                        tmp = [...formData]
                                    }
                                    tmp.push(tempData)
                                    let tempFormData = []
                                    if (formData) {
                                        tempFormData = [...formData]
                                    }
                                    tempFormData.push({
                                        ...tempData,
                                        day: daySelected
                                    })
                                    setFormData(tempFormData)

                                    setTempData({})
                                }}
                            >Add
                            </button>
                        </div>
                    </div>


                </div>
            }


        </div>
    )
}
