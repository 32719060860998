import {useState} from "react";
import {Link, useParams} from "react-router-dom";
import SearchInput from "../../../components/navbar/SearchInput/SearchInput";
import TableCell from "../../../components/Table/TableCell";
import TableHeader from "../../../components/Table/TableHeader";
import TableContainer from "../../../components/Table/TableContainer";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import Table from "../../../components/Table/Table";
import DeleteModalButton from "../../../components/base/delete-button/delete-modal-button";
import {BiTrash} from "react-icons/bi";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";


export default function VitalsTable({className, ...props}) {
    let {id} = useParams()
    let [data, filter, isLoading, deviceActions] = useListApi(URLS.getTelemetryData, {
        patientId: id
    });
    function getDeviceName(device) {
        if (device === "bp") {
            return "B.P"
        } else if (device === "sugar") {
            return "Sugar"
        } else if (device === "weight") {
            return "Weight"
        } else if (device === "sp02") {
            return "SP02"
        } else {
            return "Device"
        }
    }

    function getDeviceData(device,data){
        if(device==="bp"){
            return `${data?.sys}/${data?.dia} mmHg`
        }
        if(device==="sugar"){
            return `${data?.data} mg/dl`
        }
        if(device==="weight"){
            return `${data?.data} kg`
        }
        if(device==="sp02"){
            return `${data?.spo2} %`
        }

    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <TableContainer >
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Vitals</TableCell>
                            <TableCell>Pulse</TableCell>
                            <TableCell>Device </TableCell>
                            <TableCell>IMEI</TableCell>


                            {/* device type == sugar */}
                            <TableCell>Date & Time</TableCell>
                            {/*<TableCell>Actions</TableCell>*/}
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results?.map((row, i) => {
                            console.log(row)
                            return <TableRow key={i}>

                                <TableCell>
                                    {getDeviceData(row.assignedDevice?.device?.deviceType,row?.data)}
                                </TableCell>
                                <TableCell>
                                    {row?.data?.pul || "N/A"}
                                </TableCell>
                                <TableCell className={"items-center flex"}>
                                    <div className={" bg-brand-500 p-1 rounded-lg text-white hover:bg-brand-700  text-center"}>
                                        {getDeviceName(row.assignedDevice?.device?.deviceType)}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {/* row?.data?.ts would be time in unix time show in local format */}
                                    {row?.assignedDevice?.device?.imei}

                                </TableCell>
                                <TableCell>
                                    {/* row?.data?.ts would be time in unix time show in local format */}
                                    {new Date(parseInt(row?.data?.ts)*1000).toLocaleString()}

                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    <div className={"bg-red-100 rounded-full hover:bg-red-400 p-1 w-10 h-10 flex items-center justify-center"}>*/}
                                {/*        <BiTrash className={"text-red-500 cursor-pointer"} onClick={() => {*/}
                                {/*            console.log("Delete", row)*/}

                                {/*        }}/>*/}
                                {/*    </div>*/}

                                {/*</TableCell>*/}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.results?.length} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            deviceActions.loadNextPage()
                        }}
                        onPrev={(e) => deviceActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            deviceActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
