import React, {useState, useEffect} from 'react'
import CTA from "../../components/utils/CTA";
import IconCard from "../../components/Cards/IconCards/IconCard";
import {BiCalendar, BiLogoAndroid, BiUser} from "react-icons/bi";
import ClientTable from "./tables/ClientTable/ClientTable";
import PageTitle from "../../components/utils/PageTitle";
import ChartCard from "../../components/Cards/ChartCard/ChartCard";
import {Bar, Doughnut, Line} from "react-chartjs-2";
import {
    barLegends,
    barOptions,
    doughnutLegends,
    doughnutOptions,
    lineLegends,
    lineOptions
} from "./variabales/chartData";
import ChartLegend from "../../components/utils/ChartLegend";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ArcElement,
    BarElement,
    Legend,
} from 'chart.js';
import {HiOutlineZoomIn} from "react-icons/hi";
import CallRequestCard from "../../components/base/call-request-card/call-request-card";
import dummyCallRequest from "../../utils/dummy-data/dummy-call-request";
import {Link} from "react-router-dom";
import {FaUserDoctor} from "react-icons/fa6";
import {BsCash} from "react-icons/bs";
import {GiCash} from "react-icons/gi";
import {FaUser} from "react-icons/fa";
import useForm from "../../Hooks/useForm";
import URLS from "../../API/Axios/URLS";
import {CURRENCY} from "../../settings/settings";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
);


export default function Dashboard() {
    const [zoomChart, setZoomChart] = useState(false)
    const [form, error, loading, setFormData, actions]  = useForm()
    useEffect(() => {
        actions.post(URLS.Dashboard).then((data) => {
            setFormData(data.data)
        }).catch((error) => {
            console.log(error)
        })

    }, [])

    return (
        <div>
            <h1 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">Dashboard</h1>
            <div className={"flex flex-row  gap-5"}>
                <div className={"flex-1"}>
                    <div className={"grid gap-2  grid-cols-1 md:grid-cols-2  xl:grid-cols-2"}>
                        <IconCard
                            title={"Total Patients"}
                            color={"#0a50ee"}
                            icon={<FaUser className={"text-2xl"}/>}
                            value={form?.patients}
                        />
                        <IconCard
                            title={"Total Doctors"}
                            color={"#5d0072"}
                            icon={<FaUserDoctor className={"text-2xl"}/>}
                            value={form?.doctors}
                        />
                        <IconCard
                            title={"Payments Today"}
                            color={"#427dff"}
                            icon={<BsCash className={"text-2xl"}/>}
                            value={form?.paymentsToday?.total+" "+CURRENCY}
                        />
                        <IconCard
                            title={"Total Payments"}
                            color={"#118d00"}
                            icon={<GiCash className={"text-2xl"}/>}
                            value={form?.payments?.total+" "+CURRENCY}
                        />
                        <IconCard
                            className={"col-span-2 rounded-t-3xl rounded-b-none" }
                            title={"Total Appointments"}
                            color={"#ff01c9"}
                            icon={<BiCalendar className={"text-2xl"}/>}
                            value={form?.appointments}
                        />
                        <IconCard
                            className={"rounded-bl-3xl rounded-tl-none"}
                            title={"Upcoming Appointments"}
                            color={"#ff01c9"}
                            icon={<BiCalendar className={"text-2xl"}/>}
                            value={form?.upcoming}
                        />
                        <IconCard
                            className={"rounded-br-3xl  rounded-tr-none"}

                            title={"Past Appointments"}
                            color={"#ff01c9"}
                            icon={<BiCalendar className={"text-2xl"}/>}
                            value={form?.completed}
                        />




                    </div>

                    {/*<div className={``}>*/}
                    {/*    <ChartCard title="Bars">*/}
                    {/*        <Bar {...barOptions} />*/}
                    {/*    </ChartCard>*/}

                    {/*</div>*/}
                </div>
                {/*<div className={"hidden  flex-row lg:flex w-[350px]"}>*/}
                {/*    <div*/}
                {/*        className={"w-full flex flex-col  gap-4 rounded-md bg-white dark:bg-gray-800 dark:text-white  shadow-sm"}>*/}
                {/*        <div className={"flex px-6 justify-center items-center flex-row gap-2"}>*/}
                {/*            <p className={"font-semibold flex-1 text-xl py-3"}>*/}
                {/*                Call Request*/}
                {/*            </p>*/}

                {/*            <Link*/}
                {/*                to={"/app/calls"}*/}
                {/*                className={"cursor-pointer dark:text-purple-500 text-purple-900 font-semibold text-sm"}>*/}
                {/*                See All*/}
                {/*            </Link>*/}


                {/*        </div>*/}
                {/*        <div className={"max-h-[60vh] px-8 overflow-auto"}>*/}
                {/*            {*/}
                {/*                dummyCallRequest.map((item, index) => {*/}
                {/*                    return (*/}
                {/*                        <CallRequestCard key={index} {...item}/>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>

    )
}
