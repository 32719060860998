import {Spinner} from "react-bootstrap";


export default function PageLoading({className,...props}) {

     return (
         <div className={"mt-[50vh] m-auto flex flex-col  justify-center items-center "}>
             <Spinner/>
             <p>
                 Loading...
             </p>
         </div>

     )
 }